import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class daysSinceRender extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.calcDeltaDaysValue()
    };
  }

  calcDeltaDaysValue() {

    if (!this.props.value) return this.props.value;
    const localDate = new Date(this.props.value);
    const utcDate = new Date(`${localDate.getUTCFullYear()}-${localDate.getUTCMonth() + 1}-${localDate.getUTCDate()}`);
    utcDate.setHours(0, 0, 0, 0);
    const currentLocalDate = new Date();
    currentLocalDate.setHours(0, 0, 0, 0);

    // Calculate difference in days
    const timeDifference = currentLocalDate - utcDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference;
  }

  render() {
    return this.props.data && this.props.data.id ? this.state.value : "";
  }
}
