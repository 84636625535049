import React, { Component } from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import MessageComponent from '../FunctionalComponents/messageComponent';
import OrderComponent from '../FunctionalComponents/orderComponent';
import { apiPost, apiEdit, fetchUrl } from '../../apiActions/action';
import { saveAction } from '../../apiActions/checkFunctions';
import {
  CANCEL_API_URL,
  CUSTOMIZATION_GRID_API_URL,
  ORDERS_API_URL,
  ORDER_PROPERTY_API_URL,
  SHIP_API_URL,
  BULK_ARCHIVE,
  ORDER_EXPORT
} from '../../configurations/configApi';
import _ from 'lodash';
import BooleanRender from '../../formatters/BooleanRender';
import Permission from '../../permission/Permission';
import groupRender from '../../formatters/groupRender';
import imageRender from '../../formatters/imageRender';
import loadingRender from '../../formatters/loadingRender';
import viewRender from '../../formatters/viewRender';
import MenuList from '../../gridComponents/MenuList';
import CustomNoRowsOverlay from '../../formatters/customNoRowsOverlay';
import DateTimeRender from '../../formatters/DateTimeRender';
import { handleError } from '../FunctionalComponents/ErrorHandle';
import errorRedirect from 'views/FunctionalComponents/ErrorRedirect';
import OrderListStatusBarComponent from '../FunctionalComponents/OrderListStatusBarComponent';
import { getUTCDateTimeRange } from 'utils/datetime';

var orderStatus = [];
var pId = '';
var orderArchiveData = [];
var marketPlaces = [];
var displayColumns = [];
var gridParams = '';
var pageParams = '';
var gridParamGrid = '';
var configLable = '';
var firstComp = false;
var load = false;
var urlParams = '';
var responseOnGrid = '';

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: 'Order Id',
          field: 'order_id',
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          },
          cellRendererParams: {
            checkbox: true
          },
          cellRenderer: 'agGroupCellRenderer'
        },
        {
          headerName: 'Order No',
          field: 'order_number',
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Order Date',
          field: 'order_time',
          sortable: true,
          cellRenderer: 'DateTimeRender',
          filter: 'agDateColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Cart Id',
          // filter: "agNumberColumnFilter",
          // filterParams: {
          //   buttons: ["reset"],
          //   debounceMs: 1000,
          //   suppressAndOrCondition: true
          // },
          field: 'cart_id',
          cellRenderer: 'viewRender'
        },
        {
          headerName: 'Archive',
          field: 'is_archived',
          sortable: true,
          filter: 'agSetColumnFilter',
          cellRenderer: 'BooleanRender',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(['True', 'False']);
              }, 500);
            }
          }
        },
        {
          headerName: 'Marketplace',
          field: 'marketplace',
          sortable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(marketPlaces);
              }, 500);
            }
          }
        },
        {
          headerName: 'Order Status',
          field: 'order_status',
          sortable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: function(params) {
              // simulating async delay
              setTimeout(function() {
                params.success(orderStatus);
              }, 500);
            }
          }
        },
        {
          headerName: 'Order Total',
          field: 'platform_amount',
          sortable: true,
          valueFormatter: function(params) {
            return params.value && `${params.value}`;
          },
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Order Payout',
          field: 'payout',
          sortable: true,
          valueFormatter: function(params) {
            return params.value && `${params.value}`;
          },
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Shipping Cost',
          field: 'shipping_cost',
          sortable: true,
          valueFormatter: function(params) {
            return params.value && `${params.value}`;
          },
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Platform Discount',
          field: 'platform_discount',
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Platform Shipping Fee',
          field: 'platform_shipping_fee',
          sortable: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },
        {
          headerName: 'Platform Tax',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          },
          filter: 'agNumberColumnFilter',
          field: 'platform_tax',
          sortable: true
        },
        {
          headerName: 'Cost',
          field: 'cost',
          sortable: true
        },
        {
          headerName: 'Profit',
          field: 'profit',
          sortable: true
        },
        {
          headerName: 'Profit Margin',
          field: 'profit_margin',
          valueFormatter: function(params) {
            return (
              params.value && `${(params.value * 100).toFixed(2)} %`
            );
          },
          sortable: true
        },
        {
          headerName: 'ROI',
          field: 'roi',
          valueFormatter: function(params) {
            return (
              params.value && `${(params.value * 100).toFixed(2)} %`
            );
          },
          sortable: true
        },
        {
          headerName: 'Cart Order Id',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          },
          filter: 'agTextColumnFilter',
          field: 'cart_order_id'
        },
        {
          headerName: 'Platform Id',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          },
          filter: 'agTextColumnFilter',
          field: 'platform_id',
          sortable: true
        },
        {
          headerName: 'Currency',
          filterParams: {
            buttons: ['reset'],
            debounceMs: 1000,
            suppressAndOrCondition: true
          },
          filter: 'agTextColumnFilter',
          field: 'currency',
          sortable: true
        },
        {
          headerName: 'Order Time',
          field: 'order_relative_time',
          sortable: true
          // filter: "agTextColumnFilter",
          // filterParams: {
          //   buttons: ["reset"],
          //   debounceMs: 1000,
          //   suppressAndOrCondition: true, // it will remove AND/OR conditions
          //   filterOptions: [
          //     "contains",
          //     "notContains",
          //     "equals",
          //     "notEqual",
          //     "startsWith",
          //     "endsWith",
          //     "In List",
          //     "Not In List",
          //     // "Is Empty",
          //     // "Is Not Empty"
          //   ],
          // },
        }
      ],
      defaultColDef: {
        flex: 1,
        resizable: true,
        floatingFilter: true
      },
      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
            { field: 'title' },
            { field: 'sku' },
            {
              field: 'qty'
            }
          ],
          defaultColDef: { flex: 1, resizable: true }
        },
        getDetailRowData: function(params) {
          params.successCallback(params.data.items);
        }
      },
      rowModelType: 'serverSide',
      frameworkComponents: {
        customNoRowsOverlay: CustomNoRowsOverlay,
        groupRender: groupRender,
        imageRender: imageRender,
        loadingRender: loadingRender,
        viewRender: viewRender,
        BooleanRender: BooleanRender,
        DateTimeRender: DateTimeRender,
      },
      loadingCellRenderer: 'loadingRender',
      loadingOverlayComponent: 'loadingRender',
      context: { componentParent: this },
      listOfOrders: [],
      totalCount: 0,
      filterCount: 0,
      totals: {},
      paginationPageSize: 10,
      pagination: true,
      rowSelection: 'multiple',
      noRowsOverlayComponent: 'customNoRowsOverlay',
      noRowsOverlayComponentParams: {
        noRowsMessageFunc: function() {
          return 'Sorry - No Orders!';
        }
      },
      sideBar: 'filters',
      tooltipShowDelay: 0,
      filterStatus: false,
      order_ids: [],
      sweetAlert: '',
      showAction: false,
      countNill: true,
      saveInput: false,
      displayDropDownConfig: [], // to get multiple-labels response
      activeId: '', // to show active Label in dropdown
      saveBtn: false,
      saveCheck: false
    };
    this.fetchMessage = this.fetchMessage.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.onFilterCount = this.onFilterCount.bind(this);
    this.handleShipment = this.handleShipment.bind(this);
    this.fetchShipment = this.fetchShipment.bind(this);
    // this.onIntervalGrid = this.onIntervalGrid.bind(this);
    this.onShowAction = this.onShowAction.bind(this);
    this.handleInputSearch = this.handleInputSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleArchive = this.handleArchive.bind(this);
    this.setDefaultWidth = this.setDefaultWidth.bind(this);
  }

  componentDidMount() {
    pId = '';
    // localStorage.setItem("page", false); // to set pagination
    firstComp = false;
    load = false;
    displayColumns = [];
    responseOnGrid = '';
    gridParams = '';
    pageParams = '';
    this.fetchOrderProductDisplay('');
  }

  // this function will trigger whenever column width is changed
  onColumnMoved = () => {
    this.setState({ saveBtn: load ? true : false });
  };

  // this function will trigger whenever column visiblity is changed
  onColumnVisible = () => {
    responseOnGrid &&
      load &&
      this.setState({ saveBtn: load ? true : false });
  };

  // function to set the total and filtered count
  onFilterCount = (status, filterCount, totals) => {
    status
      ? this.setState({ filterStatus: true, countNill: true })
      : this.setState({ filterStatus: false, countNill: true });
    this.setState({
      totals,
      totalCount: totals.total_count,
      filterCount
    });
  };

  // function to hide count
  onCountNill = () => {
    this.setState({ countNill: false });
  };

  // function to get the label name
  handleInputOfSave = event => {
    this.setState({ labelOfSaved: event.target.value });
  };

  // function to set width for columns visibled
  setDefaultWidth = () => {
    displayColumns.forEach(each => {
      if (each.is_active) {
        Object.keys(each.field_widths).forEach(eachKey => {
          if (eachKey === 'image' || eachKey === 'created_on') {
            this.gridColumnApi.setColumnWidth(eachKey, 75);
          } else {
            this.gridColumnApi.setColumnWidth(
              eachKey,
              each.field_widths[eachKey]
            );
          }
        });
      }
    });
  };

  // fn triggers, whenever filter value is changed
  filterChanged() {
    this.setState({ saveBtn: load ? true : false });
    pageParams =
      Object.keys(this.gridApi.getFilterModel()).length > 0
        ? true
        : false;
    this.state.columnDefs.forEach(each => {
      if (
        Object.keys(this.gridApi.getFilterModel()).includes(
          each.field
        )
      ) {
        Array.from(
          document.getElementsByClassName(`ag-input-field-input`)
        ).forEach(o => {
          if (
            o.getAttribute('aria-label') ===
            `${each.headerName} Filter Input`
          )
            o.className += ' yellowFilterInput';
        });
      } else {
        Array.from(
          document.getElementsByClassName(`yellowFilterInput`)
        ).forEach(o => {
          if (
            o.getAttribute('aria-label') ===
            `${each.headerName} Filter Input`
          ) {
            o.classList.remove('yellowFilterInput');
          }
        });
      }
    });
  }

  // to set the initially filtered param values
  callSetFilterModel = () => {
    this.gridApi.setFilterModel(gridParams);
    pageParams = Object.keys(gridParams).length > 0 ? true : false;
    if (Object.keys(gridParams).length > 0) {
      this.state.columnDefs.forEach(each => {
        if (Object.keys(gridParams).includes(each.field)) {
          Array.from(
            document.getElementsByClassName(`ag-input-field-input`)
          ).forEach(o => {
            if (
              o.getAttribute('aria-label') ===
              `${each.headerName} Filter Input`
            )
              o.className += ' yellowFilterInput';
          });
        }
      });
    }
    Object.keys(gridParams).length === 0 &&
      this.gridApi.onFilterChanged();
  };

  // function to enable/disable input tag to get label config name
  saveAsState = getValueOfSave => {
    this.setState({ saveInput: getValueOfSave });
  };

  clearFilters = () => {
    this.gridApi.setFilterModel(null);
  };

  paginationSetUp = setUpPage => {
    localStorage.setItem('page', setUpPage);
    window.location = '/orders';
  };

  // function to get the selected orders,
  // this fn is called from groupRender component using context
  onGetList = idValue => {
    let listOfOrderIds = this.state.listOfOrders;
    if (listOfOrderIds.length === 0) {
      listOfOrderIds.push(idValue);
    } else {
      if (listOfOrderIds.includes(idValue)) {
        const index = listOfOrderIds.indexOf(idValue);
        if (index > -1) {
          listOfOrderIds.splice(index, 1);
        }
      } else {
        listOfOrderIds.push(idValue);
      }
    }
    this.setState({ listOfOrders: listOfOrderIds });
  };

  // used to get the data of selected rows
  onSelectionChanged = () => {
    let order_ids = this.gridApi
      .getSelectedRows()
      .map(eachRow => eachRow.id);
    this.setState({ order_ids, showAction: true });
  };

  // function to call grid on every 10 seconds
  // onIntervalGrid = params => {
  //   this.onGridReady(params); // to call the initial grid elements
  //   this.setState({ params: params });
  //   // function to call this grid API on every 10 seconds
  //   setInterval(() => {
  //     this.onGridReady(params);
  //   }, 100000);
  // };

  // function to show Action dropdown
  onShowAction = showActionBool => {
    this.setState({ showAction: showActionBool });
  };

  // function to check the error due to 404/500
  checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 ||
        error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  // function to remove sweetalert popups
  hideAlert = mode => {
    this.setState({ sweetAlert: '' });

    // load grid when shipment got success
    mode === 'success' && this.onGridReady(this.state.params);
    // deselect all rows when api got success or error
    (mode === 'success' || mode === 'error') &&
      this.state.params.api.deselectAll();
  };

  // function to show/hide loading overlay components
  onBtShowHideLoading = showHideState => {
    //  if true: show loading component
    // else hide loading component
    showHideState
      ? this.gridApi.showLoadingOverlay()
      : this.gridApi.hideOverlay();
  };

  // function to show no rows overlay
  onBtShowNoRows = () => {
    this.gridApi.showNoRowsOverlay();
  };

  // function to show Message pops on success/error response
  fetchMessage = (status, response, stateOfAction) => {
    // console.log("452", this.state.order_ids)
    let OderId =
      orderArchiveData && this.state.order_ids.length === 1
        ? orderArchiveData.filter(
            item => item.id === this.state.order_ids[0]
          )
        : null;
    // console.log(OderId)
    const message = (
      <MessageComponent
        action={stateOfAction}
        page="orders"
        hideAlert={this.hideAlert}
        hideOverlay={this.onBtShowHideLoading}
        response={response}
        orderId={OderId && OderId[0] && OderId[0].order_id}
        status={status}
      />
    );
    this.setState({ sweetAlert: message });
  };

  // function to call api for bulk shipment
  handleShipment = getState => {
    // console.log(getState,'getSTate')
    this.setState({ sweetAlert: '' }); // to hide input pop up of shipment
    this.onBtShowHideLoading(true); // to show loading
    apiPost(SHIP_API_URL, getState, ({ status, response }) => {
      if (status) {
        this.fetchMessage(true, response);
      } else {
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(false, response.response);
        }
      }
    });
  };

  // function to handle export
  handleExport = () => {
    this.setState({ export_loading: true });
    const headers = {
      Authorization: `Token ${localStorage.getItem('token')}`
    };

    axios(urlParams.replace(ORDERS_API_URL, ORDER_EXPORT), {
      method: 'post',
      headers,
      responseType: 'blob' //important
    })
      .then(({ data }) => {
        this.setState({ export_loading: false });
        this.fetchMessage(
          true,
          { data: { message: 'Export Initiated Successfully' } },
          'retailPublish'
        );
      })
      .catch(error => {
        this.setState({ export_loading: false });
        console.log(error);
      });
  };

  // function to fetch shipment popups
  fetchShipment = () => {
    const sweet = (
      <OrderComponent
        handleShipment={this.handleShipment}
        order_ids={this.state.order_ids}
        hideAlert={this.hideAlert}
      />
    );
    this.setState({ sweetAlert: sweet });
  };

  // function to call cancel orders
  handleCancel = () => {
    this.onBtShowHideLoading(true); // to show loading
    const data = {
      order_list: this.state.order_ids
      // market_place: "VoyageOne"
    };
    apiPost(CANCEL_API_URL, data, ({ status, response }) => {
      if (status) {
        this.fetchMessage(true, response, 'cancel');
      } else {
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(false, response.response, 'cancel');
        }
      }
    });
  };

  handleArchive = () => {
    this.onBtShowHideLoading(true); // to show loading
    if (this.state.order_ids && this.state.order_ids.length > 1) {
      this.onBtShowHideLoading(true); // to show loading
      const body = {
        order_ids: this.state.order_ids,
        archive: true
      };
      apiPost(BULK_ARCHIVE, body, ({ status, response }) => {
        if (status) {
          this.fetchMessage(true, response, 'archive');
        } else {
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (this.checkFunction(response)) {
            this.fetchMessage('redirectTrue');
          } else {
            this.fetchMessage(false, response.response, 'archive');
          }
        }
      });
    } else {
      apiEdit(
        `${ORDERS_API_URL}${this.state.order_ids}/`,
        { is_archived: true },
        'patch',
        ({ status, response }) => {
          if (status) {
            this.fetchMessage(true, response, 'archive');
          } else {
            if (
              response.response &&
              response.response.status &&
              response.response.status === 401
            ) {
              handleError(response.response);
            } else if (this.checkFunction(response)) {
              this.fetchMessage('redirectTrue');
            } else {
              this.fetchMessage(false, response.response, 'archive');
            }
          }
        }
      );
    }
  };

  handleUnArchive = () => {
    this.onBtShowHideLoading(true); // to show loading
    if (this.state.order_ids && this.state.order_ids.length > 1) {
      this.onBtShowHideLoading(true); // to show loading
      const body = {
        order_ids: this.state.order_ids,
        archive: false
      };
      apiPost(BULK_ARCHIVE, body, ({ status, response }) => {
        if (status) {
          this.fetchMessage(true, response, 'archive');
        } else {
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (this.checkFunction(response)) {
            this.fetchMessage('redirectTrue');
          } else {
            this.fetchMessage(false, response.response, 'archive');
          }
        }
      });
    } else {
      apiEdit(
        `${ORDERS_API_URL}${this.state.order_ids}/`,
        { is_archived: false },
        'patch',
        ({ status, response }) => {
          if (status) {
            this.fetchMessage(true, response, 'archive');
          } else {
            if (
              response.response &&
              response.response.status &&
              response.response.status === 401
            ) {
              handleError(response.response);
            } else if (this.checkFunction(response)) {
              this.fetchMessage('redirectTrue');
            } else {
              this.fetchMessage(false, response.response, 'archive');
            }
          }
        }
      );
    }
  };

  // function to get product properties
  fetchOrderProductDisplay = getValue => {
    fetchUrl(ORDER_PROPERTY_API_URL, ({ status, response }) => {
      if (status) {
        orderStatus = response.data.voyageone_order_status_types;
        marketPlaces = response.data.marketplaces;
        this.setState({ response: response.data });
        let value = response.data.display_config.filter(
          value => value.is_active === true
        );
        let configLables = response.data.display_config.filter(
          value => value.is_active === true
        );
        // console.log("582",configLables)
        configLable =
          configLables && configLables[0]
            ? configLables[0].label
            : null;
        localStorage.setItem(
          'configLable',
          configLables && configLables[0]
            ? configLables[0].label
            : null
        );
        displayColumns = response.data.display_config;
        gridParams =
          value && value[0].grid_params ? value[0].grid_params : {};
        this.setState({
          displayDropDownConfig: response.data.display_config
        });
        let activeProduct =
          response.data.display_config.length > 0 &&
          _.find(response.data.display_config, { is_active: true });
        activeProduct &&
          this.setState({
            activeId: activeProduct.id
          });
        getValue &&
          this.setState({ saveInput: false, saveBtn: false });
      } else {
        setTimeout(() => {
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          }
        }, 1000);
      }
    });
  };

  // initialize grid components
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setState({ params: params });
    // params.api.getToolPanelInstance('filters').expandFilters();
    // params.api.getToolPanelInstance('filters').expandFilters();

    var datasource = createServerSideDatasource(
      this.onBtShowNoRows,
      this.onFilterCount,
      this.onShowAction,
      this.onCountNill
    );
    params.api.setServerSideDatasource(datasource);

    params.api.addGlobalListener(function(type, event) {
      if (type === 'componentStateChanged' && !firstComp) {
        if (displayColumns.length > 0) {
          firstComp = true;
          params.api.gridOptionsWrapper.gridOptions.context.componentParent.setDefaultWidth(); // to set the width when grid loads
          params.api.gridOptionsWrapper.gridOptions.context.componentParent.callSetFilterModel(); // to set Filter Params in Grid Columns
        }
        displayColumns.length > 0 &&
          displayColumns.forEach(each => {
            // if is_active true, configuration is setup
            if (each.is_active) {
              Object.keys(each.fields).forEach(eachTo => {
                // negating the values of field to make the field visible
                return params.columnApi.setColumnVisible(
                  eachTo,
                  !each.fields[eachTo]
                );
              });
            }
          });
      }
      if (type === 'columnHoverChanged' && responseOnGrid) {
        load = true;
      }
    });
  };

  // function to get the label's field visiblity & width

  handleDisplayChange = event => {
    let selectedId = event.target.value;
    this.setState({ saveBtn: true, saveCheck: true });
    this.onBtShowHideLoading(true); // to show loading
    fetchUrl(
      `${CUSTOMIZATION_GRID_API_URL}${selectedId}`,
      ({ status, response }) => {
        this.onBtShowHideLoading(false); // to show loading
        this.fetchOrderProductDisplay('');
        localStorage.getItem('configLable');
        if (status) {
          this.setState({ activeId: selectedId });
          // // this.saveState("save", selectedId)
          if (response.data) {
            Object.keys(response.data.fields).forEach(eachTo => {
              if (eachTo === 'image' || eachTo === 'created_on') {
                // setting default width to img and created_on fields
                this.gridColumnApi.setColumnWidth(eachTo, 75);
              } else {
                /* setting column Width & Visiblity by
                negative the hide value getting from BE */
                this.gridColumnApi.setColumnWidth(
                  eachTo,
                  response.data.field_widths[eachTo]
                );
                this.gridColumnApi.setColumnVisible(
                  eachTo,
                  !response.data.fields[eachTo]
                );
              }
            });
            this.gridApi.setFilterModel(response.data.grid_params);
          }
        } else {
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          }
        }
      }
    );
  };

  // Function to save the configured field and api integration
  saveState = (inputMethod, prodId) => {
    let urlForSave;
    let methodOfSave;
    let paramsOfSave;

    // to get the hide status from grid for each fields
    let saveFieldsVisiblity = saveAction(
      this.gridColumnApi,
      'visiblity'
    );
    // to get the width number from grid for each fields
    let saveFieldsWidth = saveAction(this.gridColumnApi, 'width');

    this.setState({ saveBtn: false, saveCheck: false }); // to hide Save Btn
    this.onBtShowHideLoading(true); // to show loading

    let queryData = urlParams.split('?')[1];
    // queryData = `&{queryData}`

    // updating the existing labels
    if (inputMethod === 'save') {
      urlForSave = `${CUSTOMIZATION_GRID_API_URL}${prodId}/?${queryData}`;
      methodOfSave = 'put';
      paramsOfSave = {
        fields: saveFieldsVisiblity,
        field_widths: saveFieldsWidth,
        grid_params: gridParamGrid
      };
    } else {
      // creating new label
      // when saveAs method called
      urlForSave = `${CUSTOMIZATION_GRID_API_URL}?${queryData}`;
      methodOfSave = 'post';
      paramsOfSave = {
        fields: saveFieldsVisiblity,
        field_widths: saveFieldsWidth,
        label: this.state.labelOfSaved,
        grid_params: gridParamGrid,
        page_type: 'Orders',
        user: localStorage.getItem('userId')
      };
    }
    // console.log("urlsave", urlForSave)
    apiEdit(
      urlForSave,
      paramsOfSave,
      methodOfSave,
      ({ status, response }) => {
        if (status) {
          this.onBtShowHideLoading(false); // to show loading
          /* after creation is completed, getting all
        the labels and setting the created label as active */
          inputMethod === 'saveAs' &&
            this.fetchOrderProductDisplay('saveAs');
        } else {
          this.saveAsState(false); // to hide input tag for fetching label
          if (this.checkFunction(response)) {
            this.fetchMessage('redirectTrue');
          } else {
            this.fetchMessage(
              false,
              response.response,
              'cancelVisiblity'
            );
          }
        }
      }
    );
  };

  handleInputSearch = event => {
    pId = event.target.value;
    this.onGridReady(this.state.params);
  };

  handleSearch = () => {
    this.onGridReady(this.state.params);
  };

  render() {
    // console.log("426", orderArchiveData)
    const {
      autoGroupColumnDef,
      columnDefs,
      context,
      defaultColDef,
      detailCellRendererParams,
      filterStatus,
      frameworkComponents,
      getRowNodeId,
      loadingCellRenderer,
      loadingOverlayComponent,
      noRowsOverlayComponent,
      noRowsOverlayComponentParams,
      rowModelType,
      rowSelection,
      order_ids,
      showAction,
      sweetAlert,
      totalCount,
      countNill,
      tooltipShowDelay,
      saveInput,
      activeId,
      saveBtn,
      saveCheck,
      displayDropDownConfig
    } = this.state;

    const displayConfigLabels =
      displayDropDownConfig.length > 1 &&
      displayDropDownConfig.map(each => (
        <>
          {!each.is_active && (
            <option id={each.id} value={each.id} key={each.id}>
              {each.label}
            </option>
          )}
        </>
      ));
    const firstLabel =
      displayDropDownConfig.length > 0 &&
      displayDropDownConfig.map(each => (
        <>
          {each.is_active && (
            <option id={each.id} value={each.id} key={each.id}>
              {each.label}
            </option>
          )}
        </>
      ));

    return (
      <>
        <Permission />
        <MenuList />
        <div className="heading">
          <h1 id="order-title">
            Orders{' '}
            <span id="order-count">
              {countNill
                ? filterStatus
                  ? `${this.state.filterCount} of ${totalCount}`
                  : totalCount === 0
                  ? ''
                  : totalCount
                : ''}
            </span>
          </h1>
          {order_ids.length > 0 && showAction && (
            <div class="dropdown auction">
              <button onclick="myFunction()">
                <i class="fas fa-gavel" aria-hidden="true" /> Actions
              </button>
              <div id="myDropdown" class="dropdown-content">
                <p onClick={this.fetchShipment}>
                  <i
                    class="fas fa-shipping-fast"
                    aria-hidden="true"
                  />{' '}
                  Ship Orders
                </p>
                <p onClick={this.handleCancel}>
                  <i class="fas fa-ban" aria-hidden="true" /> Cancel
                  Orders
                </p>
                <p onClick={this.handleArchive}>
                  <i class="fa fa-archive" aria-hidden="true" />{' '}
                  Archive
                </p>
                <p onClick={this.handleUnArchive}>
                  <i class="fa fa-archive" aria-hidden="true" />{' '}
                  Unarchive
                </p>
              </div>
            </div>
          )}
          {urlParams ? (
            <div className="dropdown auction">
              <button onClick={this.handleExport}>
                <i className="fas fa-export" />
                {this.state.export_loading ? 'Loading' : 'Export'}
              </button>
            </div>
          ) : (
            ''
          )}
          <div className="searchbox">
            <input
              type="text"
              placeholder="Product ID Search..."
              onChange={e => this.handleInputSearch(e)}
            />
            <button onClick={() => this.handleSearch()}>
              <i className="fas fa-search" />
            </button>
          </div>
          {saveInput ? (
            <div className="profileConfig">
              <input
                type="text"
                id="labelToSave"
                name="labelToSave"
                onChange={event => this.handleInputOfSave(event)}
              />{' '}
              <button
                onClick={() => this.saveState('saveAs')}
                className="prosave"
              >
                Save
              </button>
              <button onClick={() => this.saveAsState(false)}>
                X
              </button>
            </div>
          ) : (
            displayDropDownConfig.length > 0 &&
            responseOnGrid && (
              <div className="profileConfig">
                <div className="profiles">
                  <select
                    name="config_profile"
                    id="config_profile"
                    multiple={false}
                    onChange={event =>
                      this.handleDisplayChange(event)
                    }
                  >
                    {firstLabel}
                    {displayConfigLabels}
                  </select>
                </div>
                <div className="dropdown auction">
                  <button>
                    <i className="fas fa-undo" /> Reset
                  </button>
                  <div id="myDropdown" className="dropdown-content">
                    {(saveBtn || saveCheck) && (
                      <>
                        <p
                          onClick={() =>
                            this.saveState('save', activeId)
                          }
                        >
                          <i
                            className="fas fa-save"
                            aria-hidden="true"
                          />{' '}
                          Save
                        </p>
                        <p onClick={() => this.saveAsState(true)}>
                          <i
                            className="fas fa-save"
                            aria-hidden="true"
                          />{' '}
                          Save As
                        </p>
                      </>
                    )}
                    <p onClick={() => this.clearFilters()}>
                      <i
                        className="fas fa-trash-alt"
                        aria-hidden="true"
                      />{' '}
                      Clear All Filters
                    </p>
                    <p onClick={() => this.paginationSetUp(false)}>
                      <i
                        className="fas fa-level-down-alt"
                        aria-hidden="true"
                      />{' '}
                      Enable Infinite Scrolling
                    </p>
                    <p onClick={() => this.paginationSetUp(true)}>
                      <i
                        className="fas fa-angle-double-right"
                        aria-hidden="true"
                      />{' '}
                      Enable Pagination
                    </p>
                  </div>
                </div>
                {/* )} */}
              </div>
            )
          )}
        </div>
        {sweetAlert}

        <div
          id="myGrid"
          style={{
            height: 'calc(100% - 180px)',
            width: '100%'
          }}
          className="ag-theme-alpine orders"
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={this.onGridReady}
            rowModelType={rowModelType}
            masterDetail={true}
            cacheBlockSize={100}
            maxBlocksInCache={10}
            detailCellRendererParams={detailCellRendererParams}
            frameworkComponents={frameworkComponents}
            loadingCellRenderer={loadingCellRenderer}
            loadingOverlayComponent={loadingOverlayComponent}
            getRowNodeId={getRowNodeId}
            autoGroupColumnDef={autoGroupColumnDef}
            setAlwaysShowHorizontalScroll={true}
            // rowModelType="infinite"
            context={context} // to pass the fns via this obj to other components
            rowSelection={rowSelection} // enable type of selection
            onSelectionChanged={this.onSelectionChanged.bind(this)} // handle selected rows
            rowMultiSelectWithClick={true} // enable multiple row selection with clicks
            tooltipShowDelay={tooltipShowDelay} // to show tooltip action with delay of time
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              noRowsOverlayComponentParams
            }
            onColumnResized={this.onColumnMoved.bind(this)}
            pagination={localStorage.getItem('page')} // if true, it enables pagination otherwise infinite scrolling
          />
          <OrderListStatusBarComponent totals={this.state.totals} />
        </div>
      </>
    );
  }
}

// Function to return sort params
const getSort = params => {
  return params.request.sortModel[0].colId &&
    params.request.sortModel[0].sort === 'asc'
    ? params.request.sortModel[0].colId
    : `-${params.request.sortModel[0].colId}`;
};

// Function to return filter condition key
const getCondition = (name, condition) => {
  if (['order_status'].includes(name)) {
    // console.log("name")
    return `${name}__in`;
  } else if (['marketplace'].includes(name)) {
    return `${name}__name__in`;
  } else if (condition === 'greaterThan') {
    return `${name}__gt`;
  } else if (condition === 'greaterThanOrEqual') {
    return `${name}__gte`;
  } else if (condition === 'lessThan') {
    return `${name}__lt`;
  } else if (condition === 'lessThanOrEqual') {
    return `${name}__lte`;
  } else if (condition === 'contains') {
    return `${name}__icontains`;
  } else if (condition === 'notContains') {
    return `exclude|${name}__icontains`;
  } else if (condition === 'startsWith') {
    return `${name}__istartswith`;
  } else if (condition === 'endsWith') {
    return `${name}__iendswith`;
  } else if (condition === 'equals') {
    return ['sku', 'brand', 'title', 'asin', 'condition'].includes(
      name
    )
      ? `${name}__iexact`
      : `${name}`;
  } else if (condition === 'notEqual') {
    return ['sku', 'brand', 'title', 'asin', 'condition'].includes(
      name
    )
      ? `exclude|${name}__iexact`
      : `exclude|${name}`;
  } else {
    return `${name}`;
  }
};

// Function to return filter value params
const getFilter = params => {
  var filterData;
  var rangeData = {};
  var data;
  var rangeDataList = {};

  let mapObj = Object.keys(params.request.filterModel).map(each => {
    data = getCondition(each, params.request.filterModel[each].type);

    filterData = {};
    const filterType = params.request.filterModel[each].filterType;
    const type = params.request.filterModel[each].type;

    if (filterType === 'date') {
      const [dateFrom, dateTo] = getUTCDateTimeRange(
        params.request.filterModel[each].dateFrom,
        params.request.filterModel[each].dateTo,
      );
      if (type === 'inRage' || type === 'equals' || type === 'notEqual') {
        rangeDataList[data] = [dateFrom, dateTo];
        rangeData['range'] && delete rangeData['range'];
        rangeData['range'] = rangeDataList;
      } else if (type === 'greaterThan') {
        filterData[data] = dateTo;
      } else {
        filterData[data] = dateFrom;
      }
    } else if (type === 'inRange') {
      let newRange = [];
      newRange.push(
        params.request.filterModel[each].filter,
        params.request.filterModel[each].filterTo
      );
      rangeDataList[data] = newRange;
      rangeData['range'] && delete rangeData['range'];
      rangeData['range'] = rangeDataList;
    } else if (filterType === 'set') {
      // to return checkbox values
      if (params.request.filterModel[each].values.length > 0) {
        filterData[data] =
          each === 'order_status' || each === 'marketplace'
            ? params.request.filterModel[each].values
            : params.request.filterModel[each].values[0] === 'True'
            ? 'True'
            : 'False';
      }
    } else {
      filterData[data] = params.request.filterModel[each].filter;
    }

    return filterData;
  });
  Object.keys(rangeData).length > 0 && mapObj.push(rangeData);
  return mapObj;
};

function createServerSideDatasource(
  onBtShowNoRows,
  onFilterCount,
  onShowAction,
  onCountNill
) {
  return {
    getRows: function(params) {
      setTimeout(() => {
        let sortId = '';
        let page = params.request.endRow / 100;
        let filter = [];
        var esFilter = '';
        if (
          params.request.sortModel.length > 0 ||
          Object.keys(params.request.filterModel).length > 0 ||
          params.request.groupKeys.length > 0
        ) {
          filter = getFilter(params).filter(
            e => Object.keys(e).length > 0
          );
          if (params.request.sortModel.length) {
            sortId = getSort(params);
          } else if (params.request.groupKeys.length) {
          }
        } else {
          sortId = '';
        }
        if (pageParams === true || pageParams === false) {
          var soniServe = `${ORDERS_API_URL}?page=${page}&search=${pId}&order_by=${sortId}&filter=${
            filter ? JSON.stringify(filter) : '[]'
          }`;
        }

        urlParams = soniServe;
        // console.log("2350", urlParams)
        const headers = {
          Authorization: `Token ${localStorage.getItem('token')}`
        };

        onCountNill();
        // to get the hide status from grid for each fields
        let saveFieldsVisiblity = saveAction(
          params.columnApi,
          'visiblity'
        );
        // to get the width number from grid for each fields
        let saveFieldsWidth = saveAction(params.columnApi, 'width');
        let labels = localStorage.getItem('configLable');
        const data = {
          grid_params: params.request.filterModel,
          fields: saveFieldsVisiblity,
          field_widths: saveFieldsWidth,
          label: labels
        };
        gridParamGrid = params.request.filterModel;

        if (
          (pageParams === true || pageParams === false) &&
          labels
        ) {
          axios(soniServe, {
            method: 'post',
            headers,
            data
          })
            .then(response => {
              orderArchiveData = response.data.results;
              Object.keys(params.request.filterModel).length === 0
                ? onFilterCount(false, 0, response.data.totals)
                : onFilterCount(
                    true,
                    response.data.count,
                    response.data.totals
                  );
              responseOnGrid = response.data.totals.total_count;
              if (response.data.results.length > 0) {
                onShowAction(true); // to enable Action Dropdown
                let lastRow = (results, request) => {
                  if (!results) return undefined;
                  let currentLastRow =
                    request.startRow + results.length;
                  return currentLastRow < request.endRow
                    ? currentLastRow
                    : undefined;
                };
                params.successCallback(
                  response.data.results,
                  lastRow(response.data.results, params.request)
                );
              } else {
                onBtShowNoRows();
                onShowAction(false); // to disable Action Dropdown
                params.successCallback(
                  [{ columnNameField: 'No Data' }],
                  0
                );
              }
            })
            .catch(error => {
              responseOnGrid = '';
              if (
                error.response &&
                error.response.status &&
                error.response.status === 401
              ) {
                handleError(error.response);
              } else errorRedirect(error.response);
            });
        }
      }, 1000);
    }
  };
}

export default Orders;
