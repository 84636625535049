import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import Axios from 'axios';
// ES Modules
import Icon from '@material-ui/core/Icon';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CancelIcon from '@material-ui/icons/ToggleOff';
import CheckCircleIcon from '@material-ui/icons/ToggleOn';
import copy from 'copy-to-clipboard';
import parse from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { handleError } from 'views/FunctionalComponents/ErrorHandle';
import errorRedirect from 'views/FunctionalComponents/ErrorRedirect';
import UnpublishComponent from 'views/FunctionalComponents/UnpublishComponent';
import {
  apiDelete,
  apiEdit,
  apiPost,
  fetchUrl
} from '../../apiActions/action';
import { handleNegative } from '../../apiActions/checkFunctions';
import noImage from '../../assets/img/comingSoon.jpg';
import LoadingOverlay from '../../components/overlays/LoadingOverlay';
import {
  CATALOG_API_URL,
  CHILD_LIST_PRODUCT_DETAILS,
  CHILD_PRODUCT_LIST,
  CHILD_UPDATE,
  COPY_PRODUCT,
  DELETE_PRODUCT,
  EDIT_LIST_PRODUCT_DETAILS,
  GOAT_LOWEST_PRICE,
  PARENT_PRODUCT_LIST,
  PRODUCT_FIELD_CONFIGURATION,
  PUBLISH_API_URL,
  SYNC_API_URL,
  UNPUBLISH_API_URL,
  STOCKX_SELLING_INFO
} from '../../configurations/configApi';
import { formatName } from '../../formatterActions';
import BreadCrumb from '../../gridComponents/BreadCrumb';
import MenuList from '../../gridComponents/MenuList';
import CopyProductModal from '../../Modal/CopyProductModal';
import Permission from '../../permission/Permission';
import CatalogTab from '../FunctionalComponents/catalogTab';
import MessageComponent from '../FunctionalComponents/messageComponent';
import PublishComponent from '../FunctionalComponents/publishComponent';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const columnDefs = [
  {
    headerName: '',
    checkboxSelection: true,
    minWidth: 100
  },
  {
    headerName: 'SKU',
    field: 'sku',
    sortable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset'],
      debounceMs: 1000,
      suppressAndOrCondition: true, // it will remove AND/OR conditions
      filterOptions: [
        'contains',
        'notContains',
        'equals',
        'notEqual',
        'startsWith',
        'endsWith',
        'In List',
        'Not In List'
      ]
    }
  },
  {
    headerName: 'Title',
    field: 'title',
    sortable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset'],
      debounceMs: 1000,
      suppressAndOrCondition: true, // it will remove AND/OR conditions
      filterOptions: [
        'contains',
        'notContains',
        'equals',
        'notEqual',
        'startsWith',
        'endsWith',
        'In List',
        'Not In List'
      ]
    }
  },
  {
    headerName: 'Product Type',
    field: 'product_type',
    sortable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['reset'],
      debounceMs: 1000,
      suppressAndOrCondition: true, // it will remove AND/OR conditions
      filterOptions: [
        'contains',
        'notContains',
        'equals',
        'notEqual',
        'startsWith',
        'endsWith',
        'In List',
        'Not In List'
      ]
    }
  }
];

const defaultColDef = {
  sortable: true,
  editable: true,
  flex: 1,
  floatingFilter: true
};

export default class CatalogsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props,
      allDetailResponse: [],
      loading: false,
      id: this.props.match.params.id,
      indexOfImg: 0,
      crumbsCatalog: [],
      publishIds: [],
      retailInput: false,
      retailValue: '',
      showPublish: false,
      buttonText: '',
      open: false,
      editProductValue: {},
      isEditProduct: false,
      editInput: {},
      responseAttributes: {},
      isChildTableVisible: false,
      allSelectedChildren: [],
      selectedSku: [],
      noRowsOverlayComponent: 'customNoRowsOverlay',
      loadingCellRenderer: 'loadingRender',
      loadingOverlayComponentParams: {
        loadingMessage: 'One moment please...'
      }
    };
    this.fetchProductFieldConfiguration = this.fetchProductFieldConfiguration.bind(
      this
    );
    this.fetchCatalogDetail = this.fetchCatalogDetail.bind(this);
    this.fetchPublish = this.fetchPublish.bind(this);
    this.fetchUnPublish = this.fetchUnPublish.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handlePublish = this.handlePublish.bind(this);
    this.loadingAction = this.loadingAction.bind(this);
    this.fetchCopyProduct = this.fetchCopyProduct.bind(this);
    this.closeCopyProduct = this.closeCopyProduct.bind(this);
    this.copyProductWithoutSibling = this.copyProductWithoutSibling.bind(
      this
    );
    this.hanldeRedirectForEdit = this.hanldeRedirectForEdit.bind(
      this
    );
    this.hanldeRedirectForAddSiblings = this.hanldeRedirectForAddSiblings.bind(
      this
    );
    this.hanldeRedirectForAddSiblings = this.hanldeRedirectForAddSiblings.bind(
      this
    );

    this.fetchGoatLowestPrice = this.fetchGoatLowestPrice.bind(this);
    this.fetchStockXSellingInfo = this.fetchStockXSellingInfo.bind(
      this
    );
  }

  // function to show/hide loading Overlay
  loadingAction = action => {
    action
      ? this.setState({ loading: true })
      : this.setState({ loading: false });
  };

  noRowsOverlayComponentParams = {
    noRowsMessageFunc: function() {
      return 'Sorry - no rows';
    }
  };

  onSelectionChanged = event => {
    var rowCount = event.api.getSelectedNodes();
    let skuSelected = [];
    rowCount.map(eachRow => {
      skuSelected.push(eachRow.data.sku);
    });
    this.setState({ selectedSku: skuSelected });
  };

  handleChildrenSave = () => {
    const { id } = this.props.match.params;

    const { allDetailResponse, selectedSku } = this.state;
    const { children } = allDetailResponse[0];

    const updatedChildren = [
      ...selectedSku,
      ...children.map(each => each.sku)
    ];
    const data = { product_id: id, children: updatedChildren };

    this.loadingAction(true);
    apiPost(CHILD_UPDATE, data, ({ status, response }) => {
      if (status) {
        this.loadingAction(false);
        this.fetchMessage(true, response, 'publish');
        this.setState({ selectedSku: [] });
        this.handleClickedForShowChildTable();
      } else {
        this.loadingAction(false);
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(false, response.response, 'publish');
        }
      }
    });
  };

  onGridReady = params => {
    const { allDetailResponse } = this.state;
    const { product_type, relationship_name } = allDetailResponse[0];

    if (['Master', 'Parent'].includes(product_type)) {
      const headers = {
        Authorization: `Token ${localStorage.getItem('token')}`
      };
      let url = '';
      if (product_type === 'Master') url = PARENT_PRODUCT_LIST;
      if (product_type === 'Parent') url = CHILD_PRODUCT_LIST;

      Axios(`${url}?relationship_name=${relationship_name}`, {
        method: 'get',
        headers
      })
        .then(res => {
          // console.log("res", res)
          if (res.data.results.length > 0) {
            // setChildrenProduct(res.data.results)
            params.api.applyTransaction({ add: res.data.results });
          } else {
            params.api.applyTransaction({
              add: [{ sku: 'No Data Found' }]
            });
          }

          // updateData(res.data.results)
        })
        .catch(error => {
          if (
            error.response &&
            error.response.status &&
            error.response.status === 401
          ) {
            handleError(error.response);
          } else errorRedirect(error.response);
        });
    }
  };

  copytext = skuVal => {
    copy(skuVal);
    this.setState({ buttonText: 'Copied !' });
  };

  fetchProductFieldConfiguration = () => {
    this.loadingAction(true);
    fetchUrl(PRODUCT_FIELD_CONFIGURATION, getVal => {
      if (getVal.status) {
        const {
          field_configuration,
          attribute_configuration
        } = getVal.response.data;

        const configurationfields = [
          ...field_configuration,
          ...attribute_configuration
        ];

        const configurationObj = configurationfields.reduce(
          (each, obj) => ({
            ...each,
            [obj.name]: obj.is_active
          }),
          {}
        );

        this.setState(prevState => ({
          ...prevState,
          configurationObj
        }));
      } else {
        if (
          getVal.response.response &&
          getVal.response.response.status &&
          getVal.response.response.status === 401
        ) {
          handleError(getVal.response.response);
        } else errorRedirect(getVal.response);
      }
    });
  };

  fetchCatalogDetail = id => {
    this.setState({ buttonText: '' });
    this.loadingAction(true);
    fetchUrl(`${CATALOG_API_URL}${id}/`, getVal => {
      if (getVal.status) {
        let listOfResponse = [];
        listOfResponse.push(getVal.response.data);
        this.loadingAction(false);

        // attributes
        const editAttributes = getVal.response.data.attributes.reduce(
          (each, obj) => {
            return {
              ...each,
              [obj.name]: { value: obj.value, editable: false }
            };
          },
          {}
        );
        this.setState(prevState => ({
          ...prevState,
          allDetailResponse: listOfResponse,
          retailValue: getVal.response.data.retail_price,
          editInput: {
            ...prevState.editInput,
            attributes: editAttributes
          },
          responseAttributes: editAttributes
        }));

        const goat_sku = listOfResponse[0].attributes.find(
          o => o['name'] === 'goat_sku'
        );

        if (goat_sku) {
          this.fetchGoatLowestPrice(id);
        }
        const {
          stockx_product_id,
          stockx_variant_id,
          is_parent
        } = getVal.response.data;
        console.log(
          'stockx_product_id, stockx_variant_id',
          stockx_product_id,
          stockx_variant_id
        );
        if (
          !is_parent &&
          (!stockx_product_id || !stockx_variant_id)
        ) {
          this.fetchStockXSellingInfo(id);
        }
      } else if (
        getVal.response.response &&
        getVal.response.response.status &&
        getVal.response.response.status === 401
      ) {
        handleError(getVal.response.response);
      } else errorRedirect(getVal.response);
    });
  };

  fetchGoatLowestPrice = product_id => {
    fetchUrl(
      GOAT_LOWEST_PRICE + `?product_id=${product_id}`,
      getVal => {
        if (getVal.status) {
          const { goat_lowest_price } = getVal.response.data;
          this.setState(prevState => ({
            ...prevState,
            allDetailResponse: [
              {
                ...prevState.allDetailResponse[0],
                goat_lowest_price
              }
            ]
          }));
        }
      }
    );
  };

  fetchStockXSellingInfo = product_id => {
    fetchUrl(
      STOCKX_SELLING_INFO + `?product_id=${product_id}`,
      getVal => {
        if (getVal.status) {
          const {
            stockx_product_id,
            stockx_variant_id
          } = getVal.response.data;
          this.setState(prevState => ({
            ...prevState,
            allDetailResponse: [
              {
                ...prevState.allDetailResponse[0],
                stockx_product_id,
                stockx_variant_id
              }
            ]
          }));
        }
      }
    );
  };

  componentDidMount() {
    this.props.clearCrumbs();
    const { id } = this.props.match.params;
    const idOfCatalog = [];
    idOfCatalog.push(id);
    const editProductFields = [
      'sku',
      'title',
      'subtitle',
      'brand',
      'manufacturer',
      'condition',
      'relationship_name',
      'asin',
      'upc',
      'isbn',
      'ean',
      'mpn',
      'harmonized_code',
      'short_description',
      'description',
      'flag',
      'flag_description',
      'warranty',
      'tax_product_code',
      'multipack_quantity',
      'warehouse_location',
      'store_title',
      'is_display_in_store',
      'store_description',
      'height',
      'length',
      'width',
      'weight',
      'retail_price',
      'goat_sku',
      'goat_lowest_price',
      'buy_it_now_price',
      'starting_price',
      'reserve_price',
      'store_price',
      'second_chance_price',
      'cost',
      'margin',
      'min_price',
      'max_price',
      'estimated_shipping_cost',
      'distribution_center',
      'supplier_po',
      'supplier_name',
      'supplier_code',
      'is_external_quantity_blocked',
      'total_available_quantity',
      'dc_phoenix'
    ];
    const newEditInput = editProductFields.reduce(
      (each, key) => ({
        ...each,
        [key]: { value: '', editable: false }
      }),
      {}
    );
    this.setState({
      publishIds: idOfCatalog,
      id: id,
      editInput: newEditInput
    });
    this.props.maintainBreadCrumbsFunction({
      name: 'Catalogs',
      pageUrl: '/catalogs'
    });

    let crumbObj = {};
    crumbObj.name = id;
    crumbObj.pageUrl = this.props.location.pathname;
    this.setState({
      crumbsCatalog: this.props.maintainBreadCrumbsFunction(crumbObj)
    });
    this.fetchProductFieldConfiguration();
    setTimeout(() => {
      this.fetchCatalogDetail(id);
    }, 100);
    localStorage.setItem('allProductDataPerId', JSON.stringify({}));
  }

  displayAfterImage = () => {
    this.setState({ indexOfImg: this.Carousel.state.currentSlide });
  };

  displayImage = imgIndex => {
    this.setState({ indexOfImg: imgIndex });
  };

  // function to remove sweetalert popups
  hideAlert = (mode, action) => {
    this.setState({ sweetAlert: '' });
    if (action !== 'manualPublish') {
      (mode === 'success' || mode === 'error') &&
        this.fetchCatalogDetail(this.state.id);
    }
  };

  // function to check the error due to 404/500
  checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 ||
        error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  // function to show Message pops on success/error response
  fetchMessage = (status, response, stateOfAction) => {
    const message = (
      <MessageComponent
        page="catalogs"
        action={stateOfAction}
        hideAlert={this.hideAlert}
        hideOverlay={this.loadingAction}
        response={response}
        status={status}
      />
    );
    this.setState({ sweetAlert: message });
  };

  // function to call api for bulk shipment
  handlePublish = getState => {
    // console.log(getState,'getState')
    this.loadingAction(true);
    this.setState({ sweetAlert: '' }); // to hide input pop up of shipment
    apiPost(PUBLISH_API_URL, getState, ({ status, response }) => {
      if (status) {
        this.loadingAction(false);
        this.fetchMessage(true, response, 'publish');
      } else {
        this.loadingAction(false);
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(false, response.response, 'publish');
        }
      }
    });
  };

  // function to fetch publish popups
  fetchPublish = () => {
    const sweet = (
      <PublishComponent
        handlePublish={this.handlePublish}
        product_ids={this.state.publishIds}
        hideAlert={this.hideAlert}
        toCheckPublish={this.state.allDetailResponse}
      />
    );
    this.setState({ sweetAlert: sweet });
  };

  // function to call unpublish api
  handleUnpublish = getState => {
    this.loadingAction(true);
    this.setState({ sweetAlert: '' }); // to hide input pop up of shipment
    // const data = { product_list: publishIds };
    apiPost(UNPUBLISH_API_URL, getState, ({ status, response }) => {
      if (status) {
        this.loadingAction(false);
        this.fetchMessage(true, response, 'unpublish');
      } else {
        this.loadingAction(false);
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(false, response.response, 'unpublish');
        }
      }
    });
  };

  // function to call unpublish api
  fetchUnPublish = () => {
    const sweet = (
      <UnpublishComponent
        handleUnpublish={this.handleUnpublish}
        product_ids={this.state.publishIds}
        hideAlert={this.hideAlert}
        toCheckPublish={this.state.allDetailResponse}
      />
    );
    this.setState({ sweetAlert: sweet });
  };

  // function to call sync api
  fetchSync = () => {
    this.loadingAction(true);
    const data = { product_list: this.state.publishIds };
    apiPost(SYNC_API_URL, data, ({ status, response }) => {
      if (status) {
        this.loadingAction(false);
        this.fetchMessage(true, response, 'sync');
      } else {
        this.loadingAction(false);
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(false, response.response, 'sync');
        }
      }
    });
  };

  handleRetailInput = value => {
    this.setState({
      retailInput: value,
      retailValue: value
        ? this.state.allDetailResponse[0].retail_price
        : ''
    });
  };

  handleInputChange = event => {
    this.setState({ retailValue: event.target.value });
    event.target.value
      ? this.setState({ showPublish: true })
      : this.setState({ showPublish: false });
  };

  handleManualPrice = statusOfManual => {
    this.loadingAction(true); // to show loading
    const retailValue = {
      retail_price_manually_updated: statusOfManual
    };
    const { id } = this.props.match.params;
    apiEdit(
      `${CATALOG_API_URL}${id}/`,
      retailValue,
      'patch',
      ({ status, response }) => {
        if (status) {
          this.loadingAction(false); // to show loading
          this.fetchCatalogDetail(this.state.id);
        } else {
          this.loadingAction(false); // to hide input tag for fetching label
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (this.checkFunction(response)) {
            this.fetchMessage('redirectTrue');
          } else {
            this.fetchMessage(
              false,
              response.response,
              'manualPublish'
            );
          }
        }
      }
    );
  };

  // callingtwice
  handleEditInputs = () => {
    this.setState(prevState => {
      const { editInput } = prevState;
      const nonAttributes = Object.keys(editInput)
        .filter(each => each !== 'attributes')
        .reduce(
          (each, obj) => ({
            ...each,
            [obj]: { ...editInput[obj], editable: false }
          }),
          {}
        );
      return {
        ...prevState,
        editInput: { ...editInput, ...nonAttributes },
        retailInput: false
      };
    });
  };

  handleEditInput = (id, value) => {
    const { allDetailResponse } = this.state;
    this.setState(prevState => {
      const returnObj = {
        ...prevState,
        editInput: {
          ...prevState.editInput,
          [id]: {
            ...prevState.editInput[id],
            editable: value,
            value:
              (allDetailResponse &&
                allDetailResponse[0] &&
                allDetailResponse[0][id]) ||
              typeof allDetailResponse[0][id] === 'number'
                ? allDetailResponse[0][id]
                : ''
          }
        }
      };

      return returnObj;
    });
  };

  handleEditAttributesInputChange = ({ target }) => {
    const { id, value } = target;
    // console.log(id, value,'3');
    this.setState(prevState => {
      const returnObj = {
        ...prevState,
        editInput: {
          ...prevState.editInput,
          attributes: {
            ...prevState.editInput.attributes,
            [id]: {
              ...prevState.editInput.attributes[id],
              value
            }
          }
        }
      };
      return returnObj;
    });
  };

  handleEditAttributesInput = (id, value) => {
    const { responseAttributes } = this.state;

    this.setState(prevState => {
      const returnObj = {
        ...prevState,
        editInput: {
          ...prevState.editInput,
          attributes: {
            ...prevState.editInput.attributes,
            [id]: {
              ...prevState.editInput.attributes[id],
              editable: value,
              value:
                (responseAttributes && responseAttributes[id]) ||
                typeof responseAttributes[id] === 'number'
                  ? responseAttributes[id].value
                  : ''
            }
          }
        }
      };
      return returnObj;
    });
  };

  handleEditInputChange = ({ target }) => {
    const { id, value } = target;
    // console.log( id, value,'marked')
    this.setState(prevState => {
      const returnObj = {
        ...prevState,
        editInput: {
          ...prevState.editInput,
          [id]: {
            ...prevState.editInput[id],
            value
          }
        }
      };
      // console.log(returnObj,'rtns');

      return returnObj;
    });
  };

  // editingProductfieldApi
  handleEditApiCall = publishData => {
    // console.log(publishData,'publishData')
    const { id } = this.props.match.params;

    this.loadingAction(true);

    apiEdit(
      `${CATALOG_API_URL}${id}/`,
      publishData,
      'patch',
      ({ status, response }) => {
        // console.log(response,'heysiri')
        if (status) {
          this.loadingAction(false); // to show loading
          // if (publish_id === "retail_price")
          //   this.setState({
          //     retailInput: false,
          //   });
          // else this.handleEditInput(publish_id, false);
          this.handleEditInputs();
          this.fetchMessage(true, response, 'retailPublish');
        } else {
          this.loadingAction(false); // to hide input tag for fetching label
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (this.checkFunction(response)) {
            this.fetchMessage('redirectTrue');
          } else {
            this.fetchMessage(
              false,
              response.response,
              'retailPublish'
            );
          }
        }
      }
    );
  };

  handleDropDownEditPublish = ({ target }) => {
    const { id, checked } = target; // to show loading

    const publishData = {
      [id]: checked
    };
    // console.log(publishData,'pbs')
    this.handleEditApiCall(publishData);
  };

  handleEnterClick = (event, condition, attribute = false) => {
    // console.log(event.target.id,'keyys')
    if (event.key === 'Enter' && condition) {
      if (attribute) {
        this.handleAttributesEditPublish();
      } else {
        this.handleEditPublish(event.target.id);
      }
    }
  };

  handleAttributesEditPublish = () => {
    const { editInput } = this.state;
    const editedAttributes = Object.keys(
      editInput.attributes
    ).reduce((each, obj) => {
      return {
        ...each,
        [obj]: editInput.attributes[obj].value
      };
    }, {});

    const publishData = {
      attributes: editedAttributes
    };
    this.handleEditApiCall(publishData);
  };

  handleEditPublish = id => {
    const { editInput, allDetailResponse, retailValue } = this.state;
    const editedFields = Object.keys(editInput)
      .filter(each => {
        return (
          each === id &&
          each !== 'attributes' &&
          ((editInput[each] &&
            editInput[each].value &&
            editInput[each].value.length > 0) ||
            typeof editInput[each].value === 'boolean')
        );
      })
      .reduce(
        (each, obj) => ({
          ...each,
          [obj]: editInput[obj].value
        }),
        {}
      );

    if (allDetailResponse[0].retail_price !== retailValue) {
      editedFields.retail_price = retailValue;
    }

    this.handleEditApiCall(editedFields);
  };

  // Delete product not present in CA

  handleDelete = () => {
    this.loadingAction(true); // to show loading
    const { id } = this.props.match.params;
    apiDelete(
      `${DELETE_PRODUCT}${id}/`,
      'delete',
      ({ status, response }) => {
        if (status) {
          this.loadingAction(false); // to show loading
          this.fetchMessage(true, response, 'delete');
        } else {
          this.loadingAction(false);
          if (
            response.response &&
            response.response.status &&
            response.response.status === 401
          ) {
            handleError(response.response);
          } else if (this.checkFunction(response)) {
            this.fetchMessage('redirectTrue');
          } else {
            this.fetchMessage(false, response.response, 'delete');
          }
        }
      }
    );
  };

  fetchCopyProduct = () => {
    this.setState({ open: true });
  };

  closeCopyProduct = () => {
    this.setState({ open: false });
  };

  copyProductWithoutSibling = (sku, title, productIds, quantity) => {
    let allDetailResponse = this.state.allDetailResponse;
    let data;
    data = {
      title: title,
      sku: sku,
      product: productIds,
      quantity
    };

    apiPost(COPY_PRODUCT, data, ({ status, response }) => {
      if (status) {
        this.loadingAction(false);
        this.fetchMessage(true, response, 'copy product');
        this.setState({ open: false });
      } else {
        this.loadingAction(false);
        this.setState({ open: true });
        if (
          response.response &&
          response.response.status &&
          response.response.status === 401
        ) {
          handleError(response.response);
        } else if (this.checkFunction(response)) {
          this.fetchMessage('redirectTrue');
        } else {
          this.fetchMessage(
            false,
            response.response,
            'copy product'
          );
        }
      }
    });
  };

  hanldeRedirectForEdit = (openImageSection = false) => {
    // this.fetchEditProductData("true");
    this.loadingAction(true);
    let allDetailResponse = this.state.allDetailResponse;
    let productId =
      allDetailResponse &&
      allDetailResponse[0] &&
      allDetailResponse[0].id;
    let titleParams =
      allDetailResponse &&
      allDetailResponse[0] &&
      allDetailResponse[0].title;
    fetchUrl(
      `${EDIT_LIST_PRODUCT_DETAILS}/${productId}?edit=true`,
      getVal => {
        if (getVal.status) {
          this.setState({ editProductValue: getVal.response.data });
          this.loadingAction(false);
          if (getVal.response.data) {
            // console.log(getVal.response.data)
            localStorage.setItem(
              'allProductDataPerId',
              JSON.stringify(getVal.response.data)
            );
            if (
              allDetailResponse &&
              allDetailResponse[0] &&
              allDetailResponse[0].product_type === 'Parent'
            ) {
              this.props.history.push({
                pathname: `/edit_parent_product/${titleParams}/${productId}`,
                openImageSection
              });
            } else if (
              allDetailResponse &&
              allDetailResponse[0] &&
              allDetailResponse[0].product_type &&
              allDetailResponse[0].product_type === 'Master'
            ) {
              console.log('hii master');
              this.props.history.push({
                pathname: `/edit_master_product/${productId}`,
                openImageSection
              });
            } else {
              this.props.history.push({
                pathname: `/edit_basic_product/${productId}`,
                openImageSection
              });
            }
          }
        } else {
          localStorage.setItem(
            'allProductDataPerId',
            JSON.stringify({})
          );
          this.loadingAction(false);
          if (
            getVal.response.response &&
            getVal.response.response.status &&
            getVal.response.response.status === 401
          ) {
            handleError(getVal.response.response);
          } else errorRedirect(getVal.response);
        }
      }
    );
  };

  hanldeRedirectForAddSiblings = (isSibling, productType) => {
    this.loadingAction(true);
    let allDetailResponse = this.state.allDetailResponse;
    let productId =
      allDetailResponse &&
      allDetailResponse[0] &&
      allDetailResponse[0].id;
    fetchUrl(
      `${CHILD_LIST_PRODUCT_DETAILS}/${productId}?siblings=${isSibling}`,
      getVal => {
        if (getVal.status) {
          this.setState({ editProductValue: getVal.response.data });
          this.loadingAction(false);
          if (getVal.response.data) {
            localStorage.setItem(
              'allProductDataPerId',
              JSON.stringify(getVal.response.data)
            );
            if (productType === 'Parent') {
              this.props.history.push(
                `/add_basic_product/${productId}`
              );
            }
            if (productType === 'Master') {
              this.props.history.push(
                `/add_parent_product/${productId}`
              );
            }
          }
        } else {
          localStorage.setItem(
            'allProductDataPerId',
            JSON.stringify({})
          );
          this.loadingAction(false);
          // console.log(getVal.response)
          if (
            getVal.response.response &&
            getVal.response.response.status &&
            getVal.response.response.status === 401
          ) {
            handleError(getVal.response.response);
          } else errorRedirect(getVal.response);
        }
      }
    );
  };

  handleClickedForShowChildTable = () => {
    this.setState(prevState => ({
      ...prevState,
      isChildTableVisible: !prevState.isChildTableVisible
    }));
  };

  handleDeleteChild = childSKu => {
    if (childSKu) {
      this.setState(prevState => {
        const { allDetailResponse } = prevState;
        const { children } = allDetailResponse[0];
        const filteredChildren = children.filter(
          i => i.sku !== childSKu
        );

        return {
          ...prevState,
          allDetailResponse: [
            {
              ...prevState.allDetailResponse[0],
              children: filteredChildren
            }
          ]
        };
      });
    }
  };

  render() {
    const {
      allDetailResponse,
      indexOfImg,
      loading,
      retailInput,
      retailValue,
      sweetAlert,
      editInput,
      responseAttributes,
      configurationObj
    } = this.state;
    const { id } = this.props.match.params;
    const label =
      allDetailResponse.length > 0 &&
      allDetailResponse[0].labels.length > 0 &&
      allDetailResponse[0].labels.map((each, index) => {
        return allDetailResponse[0].labels.length === index + 1
          ? `${each.name}`
          : `${each.name}, `;
      });

    const distributionCenters =
      allDetailResponse.length > 0 &&
      allDetailResponse[0].distribution_center_quantity.length > 0 &&
      allDetailResponse[0].distribution_center_quantity.map(each => {
        return (
          <tr>
            <td>{each.distribution_center.name}</td>
            <td>{each.profile.account_name}</td>
            <td>
              <div className="editPrice">
                {allDetailResponse[0].total_available_quantity}
              </div>
            </td>
          </tr>
        );
      });

    const attributesDetail =
      allDetailResponse.length > 0 &&
      allDetailResponse[0].attributes.length > 0 &&
      allDetailResponse[0].attributes.map(each =>
        this.state.configurationObj &&
        this.state.configurationObj[each.name] ? (
          <tr>
            <td>{formatName(each.name)}</td>
            <td>
              {editInput.attributes[each.name] &&
              editInput.attributes[each.name].editable ? (
                <div className="editPrice">
                  <input
                    type={'text'}
                    id={[each.name]}
                    value={
                      editInput.attributes[each.name].value
                        ? editInput.attributes[each.name].value
                        : ''
                    }
                    onKeyPress={event => {
                      this.handleEnterClick(
                        event,
                        responseAttributes[each.name].value !==
                          editInput.attributes[each.name].value,
                        true
                      );
                    }}
                    onChange={this.handleEditAttributesInputChange}
                  />
                  <CloseIcon
                    alt="Close Edit"
                    onClick={() =>
                      this.handleEditAttributesInput(
                        each.name,
                        false
                      )
                    }
                    className="on"
                  />
                </div>
              ) : (
                <div className="editPrice">
                  <b>{parse(each.value)}</b>
                  <i>
                    <CreateIcon
                      onClick={() =>
                        this.handleEditAttributesInput(
                          each.name,
                          true
                        )
                      }
                      title="Update Price"
                    />
                  </i>
                </div>
              )}
            </td>
          </tr>
        ) : null
      );

    const carousalImages =
      allDetailResponse.length > 0 &&
      allDetailResponse[0].images.length > 0 &&
      allDetailResponse[0].images.map((eachImg, index) => {
        return (
          // <a href={eachImg.url} target="_blank">
          <img
            src={eachImg.url}
            alt=""
            onClick={() => this.displayImage(index)}
          />
          // </a>
        );
      });

    return (
      <>
        <Permission />
        <MenuList />

        <div className="heading">
          <h1>Catalogs Detail</h1>
          <div className="dropdown auction">
            <button onClick="myFunction()">
              <i className="fas fa-gavel" aria-hidden="true" />{' '}
              Actions
            </button>

            {allDetailResponse.length > 0 && (
              <div id="myDropdown" className="dropdown-content">
                {allDetailResponse[0].is_published_to_voyageone &&
                allDetailResponse[0].is_published_to_shopify ? (
                  <p onClick={this.fetchUnPublish}>
                    <i
                      className="fas fa-eye-slash"
                      aria-hidden="true"
                    />{' '}
                    Unpublish
                  </p>
                ) : allDetailResponse[0].is_published_to_shopify ||
                  allDetailResponse[0].is_published_to_voyageone ? (
                  <>
                    <p onClick={this.fetchPublish}>
                      <i className="fas fa-eye" aria-hidden="true" />{' '}
                      Publish{' '}
                    </p>
                    <p onClick={this.fetchUnPublish}>
                      <i
                        className="fas fa-eye-slash"
                        aria-hidden="true"
                      />{' '}
                      Unpublish
                    </p>
                  </>
                ) : (
                  <p onClick={this.fetchPublish}>
                    <i className="fas fa-eye" aria-hidden="true" />{' '}
                    Publish{' '}
                  </p>
                )}
                {allDetailResponse[0] &&
                  allDetailResponse[0].published_details.length >
                    0 && (
                    <p onClick={this.fetchSync}>
                      <i
                        className="fas fa-sync-alt"
                        aria-hidden="true"
                      />{' '}
                      Sync{' '}
                    </p>
                  )}

                {/* <p onClick={this.hanldeRedirectForEdit}>
                  <i class="fa fa-edit" aria-hidden="true" /> Edit{" "}
                </p> */}
                {allDetailResponse &&
                allDetailResponse[0] &&
                allDetailResponse[0].product_type === 'Parent' ? (
                  <p
                    onClick={() =>
                      this.hanldeRedirectForAddSiblings(
                        true,
                        'Parent'
                      )
                    }
                  >
                    <i className="fa fa-edit" aria-hidden="true" />{' '}
                    Add Child
                  </p>
                ) : null}

                {allDetailResponse &&
                allDetailResponse[0] &&
                allDetailResponse[0].product_type === 'Master' ? (
                  <p
                    onClick={() =>
                      this.hanldeRedirectForAddSiblings(
                        true,
                        'Master'
                      )
                    }
                  >
                    <i className="fa fa-edit" aria-hidden="true" />{' '}
                    Add Parent
                  </p>
                ) : null}

                <p onClick={this.fetchCopyProduct}>
                  <i className="fa fa-copy" aria-hidden="true" />{' '}
                  Copy Product{' '}
                </p>
                {allDetailResponse &&
                allDetailResponse[0] &&
                allDetailResponse[0].is_stale ? (
                  <p onClick={() => this.handleDelete()}>
                    <i className="fa fa-trash" aria-hidden="true" />{' '}
                    Delete{' '}
                  </p>
                ) : null}
              </div>
            )}
          </div>
          <span className="reload" title="Reload">
            <Icon
              onClick={() => this.fetchCatalogDetail(id)}
              title="Reload"
            >
              refresh
            </Icon>
          </span>
        </div>
        {sweetAlert}
        <div className="content-warpper">
          {allDetailResponse.length > 0 && (
            <div className="content-body">
              <BreadCrumb crumbs={this.state.crumbsCatalog} />
              <h4>
                {' '}
                {allDetailResponse[0].title} -{' '}
                <span>{allDetailResponse[0].product_type}</span>
                {allDetailResponse[0].is_published_to_voyageone &&
                allDetailResponse[0].is_published_to_shopify ? (
                  <b className="green">
                    <i className="fas fa-eye" aria-hidden="true" />
                    <div className="listPlatform">
                      <span>Shopify</span>
                      <span>VO</span>
                    </div>
                  </b>
                ) : allDetailResponse[0]
                    .is_published_to_voyageone ? (
                  <b className="green" title={'Published to VO'}>
                    <i className="fas fa-eye" aria-hidden="true" />
                  </b>
                ) : allDetailResponse[0].is_published_to_shopify ? (
                  <b
                    className="green"
                    title={'Published to Shopify'}
                  >
                    <i className="fas fa-eye" aria-hidden="true" />
                  </b>
                ) : (
                  <b
                    className="grey"
                    title={' Not Published to VO and Shopify'}
                  >
                    <i
                      className="fas fa-eye-slash"
                      aria-hidden="true"
                    />{' '}
                  </b>
                )}
              </h4>
              <div className="content-box">
                <div className="catlog-detail">
                  <div className="catlog-image">
                    <div
                      className={
                        allDetailResponse[0].images.length > 0
                          ? 'image'
                          : 'image no-image'
                      }
                    >
                      <img
                        src={
                          allDetailResponse[0].images.length > 0
                            ? allDetailResponse[0].images[indexOfImg]
                                .url
                            : noImage
                        }
                        alt=""
                      />
                    </div>
                    <i>
                      <CreateIcon
                        onClick={() =>
                          this.hanldeRedirectForEdit(true)
                        }
                        title="Update Image"
                      />
                    </i>
                    <div className="thumbnail">
                      {allDetailResponse[0].images.length > 1 && (
                        <Carousel
                          responsive={responsive}
                          ref={el => (this.Carousel = el)}
                          afterChange={e => {
                            this.displayAfterImage(e);
                          }}
                        >
                          {carousalImages}
                        </Carousel>
                      )}
                    </div>
                  </div>
                  <div className="cat-details">
                    {configurationObj && configurationObj.sku && (
                      <>
                        <p>
                          <span>SKU</span>
                          {editInput.sku.editable ? (
                            <div className="editPrice">
                              <input
                                type="text"
                                id="sku"
                                value={
                                  editInput.sku.value
                                    ? editInput.sku.value
                                    : ''
                                }
                                onKeyPress={event =>
                                  this.handleEnterClick(
                                    // "sku",
                                    event,
                                    allDetailResponse[0].sku !==
                                      editInput.sku.value
                                  )
                                }
                                onChange={this.handleEditInputChange}
                              />
                              <CloseIcon
                                alt="Close Edit"
                                onClick={() =>
                                  this.handleEditInput('sku', false)
                                }
                                className="on"
                              />
                            </div>
                          ) : (
                            <div className="editPrice">
                              <b>{allDetailResponse[0].sku} </b>
                              <i className="clipboard">
                                {this.state.buttonText ? (
                                  <span>
                                    {this.state.buttonText}
                                  </span>
                                ) : (
                                  <FileCopyIcon
                                    onClick={() =>
                                      this.copytext(
                                        allDetailResponse[0].sku
                                      )
                                    }
                                  />
                                )}
                              </i>
                              <i>
                                <CreateIcon
                                  onClick={() =>
                                    this.handleEditInput('sku', true)
                                  }
                                  title="Update Price"
                                />
                              </i>
                            </div>
                          )}
                        </p>
                      </>
                    )}
                    {configurationObj && configurationObj.size && (
                      <>
                        <p>
                          <span>Size</span>
                          {editInput.attributes.size &&
                          editInput.attributes.size.editable ? (
                            <div className="editPrice">
                              <input
                                type="text"
                                id="size"
                                value={
                                  editInput.attributes.size.value
                                    ? editInput.attributes.size.value
                                    : ''
                                }
                                onKeyPress={event => {
                                  this.handleEnterClick(
                                    event,
                                    responseAttributes.size.value !==
                                      editInput.attributes.size
                                        .value,
                                    true
                                  );
                                }}
                                onChange={
                                  this
                                    .handleEditAttributesInputChange
                                }
                              />
                              <CloseIcon
                                alt="Close Edit"
                                onClick={() =>
                                  this.handleEditAttributesInput(
                                    'size',
                                    false
                                  )
                                }
                                className="on"
                              />
                            </div>
                          ) : (
                            <div className="editPrice">
                              <b>
                                {responseAttributes.size
                                  ? responseAttributes.size.value
                                  : 'N/A'}
                              </b>
                              <i>
                                <CreateIcon
                                  onClick={() =>
                                    this.handleEditAttributesInput(
                                      'size',
                                      true
                                    )
                                  }
                                  title="Update Price"
                                />
                              </i>
                            </div>
                          )}
                        </p>
                      </>
                    )}

                    <p>
                      <span>Retail Price</span>{' '}
                      {retailInput ? (
                        <div className="editPrice">
                          <input
                            type="number"
                            min="1"
                            value={retailValue ? retailValue : ''}
                            // onKeyPress={event => handleNegative(event)}
                            onKeyPress={event => {
                              handleNegative(event);
                              this.handleEnterClick(
                                // "retail_price",
                                event,
                                allDetailResponse[0].retail_price !==
                                  retailValue
                              );
                            }}
                            onChange={event =>
                              this.handleInputChange(event)
                            }
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleRetailInput(false)
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          {allDetailResponse[0].retail_price &&
                            `$ ${allDetailResponse[0].retail_price}`}
                          {allDetailResponse[0].retail_price &&
                          allDetailResponse[0]
                            .retail_price_manually_updated ? (
                            <i
                              className="fas fa-flag"
                              aria-hidden="true"
                              title="Manually Updated"
                            />
                          ) : (
                            ''
                          )}
                          {/* {allDetailResponse[0].is_published_to_voyageone || allDetailResponse[0].is_published_to_shopify ? (
                            <> */}
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleRetailInput(true)
                              }
                              title="Update Price"
                            />
                          </i>
                          {/* TO UNCOMMENT */}
                          {/* <div className="toggleBtn">
                                {allDetailResponse[0]
                                  .retail_price_manually_updated ? (
                                  <b title="Disable Manual Price">
                                    <CancelIcon
                                      alt="Disable Manual Price"
                                      onClick={() =>
                                        this.handleManualPrice("False")
                                      }
                                      className="on"
                                    />
                                  </b>
                                ) : (
                                  <b title="Enable Manual Price">
                                    <CheckCircleIcon
                                      alt="Enable Manual Price"
                                      onClick={() => this.handleManualPrice("True")}
                                      className="off"
                                    />
                                  </b>
                                )}
                              </div> */}
                          {/* </>
                          ) : (
                            ""
                          )} */}
                        </div>
                      )}
                    </p>
                    {allDetailResponse[0].is_in_relationship ? (
                      <p>
                        <span>Relationship</span>
                        {allDetailResponse[0].relationship_name}{' '}
                      </p>
                    ) : (
                      ''
                    )}
                    {configurationObj &&
                      configurationObj.dc_phoenix && (
                        <>
                          <p>
                            <span>Phoenix DC Qty</span>
                            {editInput.dc_phoenix.editable ? (
                              <div className="editPrice">
                                <input
                                  type="number"
                                  id="dc_phoenix"
                                  value={
                                    editInput.dc_phoenix.value ||
                                    typeof editInput.dc_phoenix
                                      .value === 'number'
                                      ? editInput.dc_phoenix.value
                                      : ''
                                  }
                                  onKeyPress={event =>
                                    this.handleEnterClick(
                                      // "dc_phoenix",
                                      event,
                                      allDetailResponse[0]
                                        .dc_phoenix !==
                                        editInput.dc_phoenix.value
                                    )
                                  }
                                  onChange={
                                    this.handleEditInputChange
                                  }
                                />
                                <CloseIcon
                                  alt="Close Edit"
                                  onClick={() =>
                                    this.handleEditInput(
                                      'dc_phoenix',
                                      false
                                    )
                                  }
                                  className="on"
                                />
                              </div>
                            ) : (
                              <div className="editPrice">
                                <b>
                                  {allDetailResponse[0].dc_phoenix}
                                </b>
                                <i>
                                  <CreateIcon
                                    onClick={() =>
                                      this.handleEditInput(
                                        'dc_phoenix',
                                        true
                                      )
                                    }
                                    title="Update Price"
                                  />
                                </i>
                              </div>
                            )}
                          </p>
                        </>
                      )}

                    {/* <p>
                      <span>Price MSRP</span>{" "}
                      {Number(allDetailResponse[0].published_details.price_msrp)
                        ? `$ ${allDetailResponse[0].published_details.price_msrp
                        }`
                        : allDetailResponse[0].published_details
                          .price_msrp}{" "}
                    </p> */}
                    {/* <p> */}
                    {/* <span>Price Cost</span>{" "}
                      {Number(allDetailResponse[0].published_details.price_cost)
                        ? `$ ${allDetailResponse[0].published_details.price_cost
                        }`
                        : allDetailResponse[0].published_details
                          .price_cost}{" "} */}
                    {/* </p> */}
                    {/* <p>
                      <span>Margin</span>{" "}
                      {Number(allDetailResponse[0].published_details.margin)
                        ? `${allDetailResponse[0].published_details.margin} %`
                        : allDetailResponse[0].published_details.margin}{" "}
                    </p> */}
                    {configurationObj && configurationObj.labels && (
                      <p>
                        <span>Labels</span>
                        <b>{label} </b>
                      </p>
                    )}
                    {configurationObj &&
                      configurationObj.created_on && (
                        <p>
                          <span>Created</span>{' '}
                          {allDetailResponse[0].created_on}
                        </p>
                      )}
                    {configurationObj &&
                      configurationObj.updated_on && (
                        <p>
                          <span>Last Updated</span>
                          {allDetailResponse[0].updated_on}
                        </p>
                      )}
                    <div
                      style={{
                        marginTop: '50px',
                        // minHeight: "100px",
                        maxHeight: '100px',
                        overflow: 'auto'
                      }}
                    >
                      <table>
                        {(allDetailResponse &&
                          allDetailResponse[0] &&
                          allDetailResponse[0]
                            .is_published_to_shopify) ||
                        allDetailResponse[0]
                          .is_published_to_voyageone ? (
                          <thead>
                            <tr>
                              <th
                                style={{
                                  textAlign: 'center',
                                  verticalAlign: 'middle'
                                }}
                              >
                                Marketplace
                              </th>
                              <th
                                style={{
                                  textAlign: 'center',
                                  verticalAlign: 'middle'
                                }}
                              >
                                Selling Cost
                              </th>
                              {/* <th style={{ textAlign: "center", verticalAlign: "middle" }}>Price MSRP</th> */}
                              <th
                                style={{
                                  textAlign: 'center',
                                  verticalAlign: 'middle'
                                }}
                              >
                                Margin
                              </th>
                            </tr>
                          </thead>
                        ) : null}
                        <tbody>
                          {allDetailResponse &&
                            allDetailResponse[0] &&
                            allDetailResponse[0].published_details.map(
                              each => {
                                return (
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: 'center',
                                        verticalAlign: 'middle'
                                      }}
                                    >
                                      {each.marketplace
                                        ? each.marketplace
                                        : null}
                                    </td>
                                    <td
                                      style={{
                                        textAlign: 'center',
                                        verticalAlign: 'middle'
                                      }}
                                    >
                                      {each.price_cost
                                        ? each.price_cost
                                        : 0}
                                    </td>
                                    {/* <td style={{ textAlign: "center", verticalAlign: "middle" }}>{each.price_msrp ? each.price_msrp : 0}</td> */}
                                    <td
                                      style={{
                                        textAlign: 'center',
                                        verticalAlign: 'middle'
                                      }}
                                    >
                                      {' '}
                                      {each.margin}%
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="recetOrders">
                  <CatalogTab
                    id={id}
                    orders={allDetailResponse[0].order_products}
                    productHistory={allDetailResponse[0].history}
                    mpn={allDetailResponse[0].mpn}
                    isPublishToShopify={
                      allDetailResponse[0].is_published_to_shopify
                    }
                    isPublishToVo={
                      allDetailResponse[0].is_published_to_voyageone
                    }
                  />
                </div>
              </div>
              <div className="catlog-basicinfo">
                <h5>Basic Info</h5>
                {configurationObj && configurationObj.manufacturer && (
                  <>
                    <p>
                      <span>SKU</span>
                    </p>
                    {editInput.sku.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="sku"
                          value={
                            editInput.sku.value
                              ? editInput.sku.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "sku",
                              event,
                              allDetailResponse[0].sku !==
                                editInput.sku.value
                            )
                          }
                          // if (event.key === "Enter") handleLogin();
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('sku', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].sku} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('sku', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.title && (
                  <>
                    <p>
                      <span>Title</span>
                    </p>
                    {editInput.title.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="title"
                          value={
                            editInput.title.value
                              ? editInput.title.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "title",
                              event,
                              allDetailResponse[0].title !==
                                editInput.title.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('title', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].title} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('title', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.subtitle && (
                  <>
                    <p>
                      <span>Subtitle</span>
                    </p>
                    {editInput.subtitle.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="subtitle"
                          value={
                            editInput.subtitle.value
                              ? editInput.subtitle.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "subtitle",
                              event,
                              allDetailResponse[0].subtitle !==
                                editInput.subtitle.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('subtitle', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].subtitle} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('subtitle', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.brand && (
                  <>
                    <p>
                      <span>Brand</span>
                    </p>
                    {editInput.brand.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="brand"
                          value={
                            editInput.brand.value
                              ? editInput.brand.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "brand",
                              event,
                              allDetailResponse[0].brand !==
                                editInput.brand.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('brand', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].brand} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('brand', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.manufacturer && (
                  <>
                    <p>
                      <span>Manufacturer</span>
                    </p>
                    {editInput.manufacturer.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="manufacturer"
                          value={
                            editInput.manufacturer.value
                              ? editInput.manufacturer.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "manufacturer",
                              event,
                              allDetailResponse[0].manufacturer !==
                                editInput.manufacturer.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput(
                              'manufacturer',
                              false
                            )
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].manufacturer} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput(
                                'manufacturer',
                                true
                              )
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}

                {/* Editing the category option */}
                {configurationObj &&
                  configurationObj.relationship_name && (
                    <>
                      <p>
                        <span>Category</span>
                      </p>
                      {editInput.relationship_name.editable ? (
                        <div className="editPrice">
                          <input
                            type="text"
                            id="relationship_name"
                            value={
                              editInput.relationship_name.value
                                ? editInput.relationship_name.value
                                : ''
                            }
                            onKeyPress={event =>
                              this.handleEnterClick(
                                event,
                                allDetailResponse[0]
                                  .relationship_name !==
                                  editInput.relationship_name.value
                              )
                            }
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'relationship_name',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          <b>
                            {allDetailResponse[0].relationship_name}{' '}
                          </b>
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'relationship_name',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}

                {configurationObj && configurationObj.condition && (
                  <>
                    <p>
                      <span>Condition</span>
                    </p>
                    {editInput.condition.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="condition"
                          value={
                            editInput.condition.value
                              ? editInput.condition.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "condition",
                              event,
                              allDetailResponse[0].condition !==
                                editInput.condition.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('condition', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].condition} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('condition', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.asin && (
                  <>
                    <p>
                      <span>ASIN</span>
                    </p>
                    {editInput.asin.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="asin"
                          value={
                            editInput.asin.value
                              ? editInput.asin.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "asin",
                              event,
                              allDetailResponse[0].asin !==
                                editInput.asin.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('asin', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].asin} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('asin', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.upc && (
                  <>
                    <p>
                      <span>UPC</span>
                    </p>
                    {editInput.upc.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="upc"
                          value={
                            editInput.upc.value
                              ? editInput.upc.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "upc",
                              event,
                              allDetailResponse[0].upc !==
                                editInput.upc.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('upc', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].upc} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('upc', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.isbn && (
                  <>
                    <p>
                      <span>ISBN</span>
                    </p>
                    {editInput.isbn.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="isbn"
                          value={
                            editInput.isbn.value
                              ? editInput.isbn.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "isbn",
                              event,
                              allDetailResponse[0].isbn !==
                                editInput.isbn.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('isbn', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].isbn} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('isbn', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.ean && (
                  <>
                    <p>
                      <span>EAN</span>
                    </p>
                    {editInput.ean.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="ean"
                          value={
                            editInput.ean.value
                              ? editInput.ean.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "ean",
                              event,
                              allDetailResponse[0].ean !==
                                editInput.ean.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('ean', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].ean} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('ean', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.mpn && (
                  <>
                    <p>
                      <span>MPN</span>
                    </p>
                    {editInput.mpn.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="mpn"
                          value={
                            editInput.mpn.value
                              ? editInput.mpn.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "mpn",
                              event,
                              allDetailResponse[0].mpn !==
                                editInput.mpn.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('mpn', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].mpn} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('mpn', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj &&
                  configurationObj.harmonized_code && (
                    <>
                      <p>
                        <span>Harmonized Code</span>
                      </p>
                      {editInput.harmonized_code.editable ? (
                        <div className="editPrice">
                          <input
                            type="text"
                            id="harmonized_code"
                            value={
                              editInput.harmonized_code.value
                                ? editInput.harmonized_code.value
                                : ''
                            }
                            onKeyPress={event =>
                              this.handleEnterClick(
                                // "harmonized_code",
                                event,
                                allDetailResponse[0]
                                  .harmonized_code !==
                                  editInput.harmonized_code.value
                              )
                            }
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'harmonized_code',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          <b>
                            {allDetailResponse[0].harmonized_code}{' '}
                          </b>
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'harmonized_code',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj &&
                  configurationObj.short_description && (
                    <>
                      <p>
                        <span>Short Description</span>
                      </p>
                      {editInput.short_description.editable ? (
                        <div className="editPrice">
                          <input
                            type="text"
                            id="short_description"
                            value={
                              editInput.short_description.value
                                ? editInput.short_description.value
                                : ''
                            }
                            onKeyPress={event =>
                              this.handleEnterClick(
                                // "short_description",
                                event,
                                allDetailResponse[0]
                                  .short_description !==
                                  editInput.short_description.value
                              )
                            }
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'short_description',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          <b>
                            {allDetailResponse[0].short_description}{' '}
                          </b>
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'short_description',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj && configurationObj.description && (
                  <>
                    <p>
                      <span>Description</span>
                    </p>
                    {editInput.description.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="description"
                          value={
                            editInput.description.value
                              ? editInput.description.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "description",
                              event,
                              allDetailResponse[0].description !==
                                editInput.description.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput(
                              'description',
                              false
                            )
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].description} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput(
                                'description',
                                true
                              )
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.flag && (
                  <>
                    <p>
                      <span>Flag</span>
                    </p>
                    {editInput.flag.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="flag"
                          value={
                            editInput.flag.value
                              ? editInput.flag.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "flag",
                              event,
                              allDetailResponse[0].flag !==
                                editInput.flag.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('flag', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].flag} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('flag', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj &&
                  configurationObj.flag_description && (
                    <>
                      <p>
                        <span>Flag Description</span>
                      </p>
                      {editInput.flag_description.editable ? (
                        <div className="editPrice">
                          <input
                            type="text"
                            id="flag_description"
                            value={
                              editInput.flag_description.value
                                ? editInput.flag_description.value
                                : ''
                            }
                            onKeyPress={event =>
                              this.handleEnterClick(
                                // "flag_description",
                                event,
                                allDetailResponse[0]
                                  .flag_description !==
                                  editInput.flag_description.value
                              )
                            }
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'flag_description',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          <b>
                            {allDetailResponse[0].flag_description}{' '}
                          </b>
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'flag_description',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj && configurationObj.warranty && (
                  <>
                    <p>
                      <span>Warranty</span>
                    </p>
                    {editInput.warranty.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="warranty"
                          value={
                            editInput.warranty.value
                              ? editInput.warranty.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "warranty",
                              event,
                              allDetailResponse[0].warranty !==
                                editInput.warranty.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('warranty', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].warranty} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('warranty', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj &&
                  configurationObj.tax_product_code && (
                    <>
                      <p>
                        <span>Tax Code</span>
                      </p>
                      {editInput.tax_product_code.editable ? (
                        <div className="editPrice">
                          <input
                            type="text"
                            id="tax_product_code"
                            value={
                              editInput.tax_product_code.value
                                ? editInput.tax_product_code.value
                                : ''
                            }
                            onKeyPress={event =>
                              this.handleEnterClick(
                                // "tax_product_code",
                                event,
                                allDetailResponse[0]
                                  .tax_product_code !==
                                  editInput.tax_product_code.value
                              )
                            }
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'tax_product_code',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          <b>
                            {allDetailResponse[0].tax_product_code}{' '}
                          </b>
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'tax_product_code',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj &&
                  configurationObj.multipack_quantity && (
                    <>
                      <p>
                        <span>Multipack Quantity</span>
                      </p>
                      {editInput.multipack_quantity.editable ? (
                        <div className="editPrice">
                          <input
                            type="number"
                            min="1"
                            id="multipack_quantity"
                            value={
                              editInput.multipack_quantity.value ||
                              typeof editInput.multipack_quantity
                                .value === 'number'
                                ? editInput.multipack_quantity.value
                                : ''
                            }
                            onKeyPress={event => {
                              handleNegative(event);
                              this.handleEnterClick(
                                // "multipack_quantity",
                                event,
                                allDetailResponse[0]
                                  .multipack_quantity !==
                                  editInput.multipack_quantity.value
                              );
                            }}
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'multipack_quantity',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          <b>
                            {allDetailResponse[0].multipack_quantity}{' '}
                          </b>
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'multipack_quantity',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj &&
                  configurationObj.warehouse_location && (
                    <>
                      <p>
                        <span>Warehouse Location</span>
                      </p>
                      {editInput.warehouse_location.editable ? (
                        <div className="editPrice">
                          <input
                            type="text"
                            id="warehouse_location"
                            value={
                              editInput.warehouse_location.value
                                ? editInput.warehouse_location.value
                                : ''
                            }
                            onKeyPress={event =>
                              this.handleEnterClick(
                                // "warehouse_location",
                                event,
                                allDetailResponse[0]
                                  .warehouse_location !==
                                  editInput.warehouse_location.value
                              )
                            }
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'warehouse_location',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          <b>
                            {allDetailResponse[0].warehouse_location}{' '}
                          </b>
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'warehouse_location',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj && configurationObj.store_title && (
                  <>
                    <p>
                      <span>Store Title</span>
                    </p>
                    {editInput.store_title.editable ? (
                      <div className="editPrice">
                        <input
                          type="text"
                          id="store_title"
                          value={
                            editInput.store_title.value
                              ? editInput.store_title.value
                              : ''
                          }
                          onKeyPress={event =>
                            this.handleEnterClick(
                              // "store_title",
                              event,
                              allDetailResponse[0].store_title !==
                                editInput.store_title.value
                            )
                          }
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput(
                              'store_title',
                              false
                            )
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].store_title} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput(
                                'store_title',
                                true
                              )
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj &&
                  configurationObj.is_display_in_store && (
                    <>
                      <p>
                        <span>Display In Store</span>
                      </p>
                      {editInput.is_display_in_store.editable ? (
                        <div className="editPrice">
                          <input
                            type="checkbox"
                            id="is_display_in_store"
                            checked={
                              editInput.is_display_in_store.value
                            }
                            onChange={this.handleDropDownEditPublish}
                          />
                          <p>
                            {editInput.is_display_in_store.value
                              ? 'Yes'
                              : 'No'}
                          </p>
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'is_display_in_store',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          <b>
                            {allDetailResponse[0].is_display_in_store
                              ? 'Yes'
                              : 'No'}
                          </b>
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'is_display_in_store',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj &&
                  configurationObj.store_description && (
                    <>
                      <p>
                        <span>Store Description</span>
                      </p>
                      {editInput.store_description.editable ? (
                        <div className="editPrice">
                          <input
                            type="text"
                            id="store_description"
                            value={
                              editInput.store_description.value
                                ? editInput.store_description.value
                                : ''
                            }
                            onKeyPress={event =>
                              this.handleEnterClick(
                                // "store_description",
                                event,
                                allDetailResponse[0]
                                  .store_description !==
                                  editInput.store_description.value
                              )
                            }
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'store_description',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          <b>
                            {allDetailResponse[0].store_description}{' '}
                          </b>
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'store_description',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj &&
                  (configurationObj.supplier_name ||
                    configurationObj.supplier_po) && (
                    <>
                      <h5>Supplier</h5>
                      {configurationObj &&
                        configurationObj.supplier_name && (
                          <>
                            <p>
                              <span>Supplier</span>
                            </p>
                            {editInput.supplier_name.editable ? (
                              <div className="editPrice">
                                <input
                                  type="text"
                                  id="supplier_name"
                                  value={
                                    editInput.supplier_name.value
                                      ? editInput.supplier_name.value
                                      : ''
                                  }
                                  onKeyPress={event =>
                                    this.handleEnterClick(
                                      // "supplier_name",
                                      event,
                                      allDetailResponse[0]
                                        .supplier_name !==
                                        editInput.supplier_name.value
                                    )
                                  }
                                  // if (event.key === "Enter") handleLogin();
                                  onChange={
                                    this.handleEditInputChange
                                  }
                                />
                                <CloseIcon
                                  alt="Close Edit"
                                  onClick={() =>
                                    this.handleEditInput(
                                      'supplier_name',
                                      false
                                    )
                                  }
                                  className="on"
                                />
                              </div>
                            ) : (
                              <div className="editPrice">
                                <b>
                                  {
                                    allDetailResponse[0]
                                      .supplier_name
                                  }
                                </b>
                                <i>
                                  <CreateIcon
                                    onClick={() =>
                                      this.handleEditInput(
                                        'supplier_name',
                                        true
                                      )
                                    }
                                    title="Update Price"
                                  />
                                </i>
                              </div>
                            )}
                          </>
                        )}
                      {configurationObj &&
                        configurationObj.supplier_po && (
                          <>
                            <p>
                              <span>Supplier PO</span>
                            </p>
                            {editInput.supplier_po.editable ? (
                              <div className="editPrice">
                                <input
                                  type="text"
                                  id="supplier_po"
                                  value={
                                    editInput.supplier_po.value
                                      ? editInput.supplier_po.value
                                      : ''
                                  }
                                  onKeyPress={event =>
                                    this.handleEnterClick(
                                      // "supplier_po",
                                      event,
                                      allDetailResponse[0]
                                        .supplier_po !==
                                        editInput.supplier_po.value
                                    )
                                  }
                                  // if (event.key === "Enter") handleLogin();
                                  onChange={
                                    this.handleEditInputChange
                                  }
                                />
                                <CloseIcon
                                  alt="Close Edit"
                                  onClick={() =>
                                    this.handleEditInput(
                                      'supplier_po',
                                      false
                                    )
                                  }
                                  className="on"
                                />
                              </div>
                            ) : (
                              <div className="editPrice">
                                <b>
                                  {allDetailResponse[0].supplier_po}
                                </b>
                                <i>
                                  <CreateIcon
                                    onClick={() =>
                                      this.handleEditInput(
                                        'supplier_po',
                                        true
                                      )
                                    }
                                    title="Update Price"
                                  />
                                </i>
                              </div>
                            )}
                          </>
                        )}
                    </>
                  )}
              </div>
              <div className="otherInfo">
                <h5>Dimensions </h5>
                {configurationObj && configurationObj.height && (
                  <>
                    <p>
                      <span>Height(inches)</span>
                    </p>
                    {editInput.height.editable ? (
                      <div className="editPrice">
                        <input
                          type="number"
                          min="1"
                          id="height"
                          value={
                            editInput.height.value
                              ? editInput.height.value
                              : ''
                          }
                          onKeyPress={event => {
                            handleNegative(event);
                            this.handleEnterClick(
                              // "height",
                              event,
                              allDetailResponse[0].height !==
                                editInput.height.value
                            );
                          }}
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('height', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].height} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('height', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.length && (
                  <>
                    <p>
                      <span>Length(inches)</span>
                    </p>
                    {editInput.length.editable ? (
                      <div className="editPrice">
                        <input
                          type="number"
                          min="1"
                          id="length"
                          value={
                            editInput.length.value
                              ? editInput.length.value
                              : ''
                          }
                          onKeyPress={event => {
                            handleNegative(event);
                            this.handleEnterClick(
                              // "length",
                              event,
                              allDetailResponse[0].length !==
                                editInput.length.value
                            );
                          }}
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('length', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].length} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('length', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.width && (
                  <>
                    <p>
                      <span>Width(inches)</span>
                    </p>
                    {editInput.width.editable ? (
                      <div className="editPrice">
                        <input
                          type="number"
                          min="1"
                          id="width"
                          value={
                            editInput.width.value
                              ? editInput.width.value
                              : ''
                          }
                          onKeyPress={event => {
                            handleNegative(event);
                            this.handleEnterClick(
                              // "width",
                              event,
                              allDetailResponse[0].width !==
                                editInput.width.value
                            );
                          }}
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('width', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].width} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('width', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.weight && (
                  <>
                    <p>
                      <span>Weight (lb)</span>
                    </p>
                    {editInput.weight.editable ? (
                      <div className="editPrice">
                        <input
                          type="number"
                          min="1"
                          id="weight"
                          value={
                            editInput.weight.value
                              ? editInput.weight.value
                              : ''
                          }
                          onKeyPress={event => {
                            handleNegative(event);
                            this.handleEnterClick(
                              // "weight",
                              event,
                              allDetailResponse[0].weight !==
                                editInput.weight.value
                            );
                          }}
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('weight', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        <b>{allDetailResponse[0].weight} </b>
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('weight', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                <h5>Pricing</h5>
                {configurationObj && configurationObj.retail_price && (
                  <>
                    <p>
                      <span>Retail Price</span>{' '}
                    </p>
                    {retailInput ? (
                      <div className="editPrice">
                        <input
                          type="number"
                          min="1"
                          value={retailValue ? retailValue : ''}
                          // onKeyPress={event => handleNegative(event)}
                          onKeyPress={event => {
                            handleNegative(event);
                            this.handleEnterClick(
                              // "retail_price",
                              event,
                              allDetailResponse[0].retail_price !==
                                retailValue
                            );
                          }}
                          onChange={event =>
                            this.handleInputChange(event)
                          }
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleRetailInput(false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        {allDetailResponse[0].retail_price &&
                          `$ ${allDetailResponse[0].retail_price}`}
                        {allDetailResponse[0].retail_price &&
                        allDetailResponse[0]
                          .retail_price_manually_updated ? (
                          <i
                            className="fas fa-flag"
                            aria-hidden="true"
                            title="Manually Updated"
                          />
                        ) : (
                          ''
                        )}
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleRetailInput(true)
                            }
                            title="Update Price"
                          />
                        </i>
                        <div className="toggleBtn">
                          {allDetailResponse[0]
                            .retail_price_manually_updated ? (
                            <b title="Disable Manual Price">
                              <CancelIcon
                                alt="Disable Manual Price"
                                onClick={() =>
                                  this.handleManualPrice('False')
                                }
                                className="on"
                              />
                            </b>
                          ) : (
                            <b title="Enable Manual Price">
                              <CheckCircleIcon
                                alt="Enable Manual Price"
                                onClick={() =>
                                  this.handleManualPrice('True')
                                }
                                className="off"
                              />
                            </b>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {configurationObj &&
                  configurationObj.buy_it_now_price && (
                    <>
                      <p>
                        <span>Buy-It-Now-Price</span>
                      </p>
                      {editInput.buy_it_now_price.editable ? (
                        <div className="editPrice">
                          <input
                            type="number"
                            min="1"
                            id="buy_it_now_price"
                            value={
                              editInput.buy_it_now_price.value
                                ? editInput.buy_it_now_price.value
                                : ''
                            }
                            onKeyPress={event => {
                              handleNegative(event);
                              this.handleEnterClick(
                                // "buy_it_now_price",
                                event,
                                allDetailResponse[0]
                                  .buy_it_now_price !==
                                  editInput.buy_it_now_price.value
                              );
                            }}
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'buy_it_now_price',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          {allDetailResponse[0].buy_it_now_price && (
                            <b>
                              ${' '}
                              {allDetailResponse[0].buy_it_now_price}
                            </b>
                          )}
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'buy_it_now_price',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj &&
                  configurationObj.starting_price && (
                    <>
                      <p>
                        <span>Starting Price</span>
                      </p>
                      {editInput.starting_price.editable ? (
                        <div className="editPrice">
                          <input
                            type="number"
                            min="1"
                            id="starting_price"
                            value={
                              editInput.starting_price.value
                                ? editInput.starting_price.value
                                : ''
                            }
                            onKeyPress={event => {
                              handleNegative(event);
                              this.handleEnterClick(
                                // "starting_price",
                                event,
                                allDetailResponse[0]
                                  .starting_price !==
                                  editInput.starting_price.value
                              );
                            }}
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'starting_price',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          {allDetailResponse[0].starting_price && (
                            <b>
                              $ {allDetailResponse[0].starting_price}
                            </b>
                          )}
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'starting_price',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj && configurationObj.reserve_price && (
                  <>
                    <p>
                      <span>Reserve Price</span>
                    </p>
                    {editInput.reserve_price.editable ? (
                      <div className="editPrice">
                        <input
                          type="number"
                          min="1"
                          id="reserve_price"
                          value={
                            editInput.reserve_price.value
                              ? editInput.reserve_price.value
                              : ''
                          }
                          onKeyPress={event => {
                            handleNegative(event);
                            this.handleEnterClick(
                              // "reserve_price",
                              event,
                              allDetailResponse[0].reserve_price !==
                                editInput.reserve_price.value
                            );
                          }}
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput(
                              'reserve_price',
                              false
                            )
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        {allDetailResponse[0].reserve_price && (
                          <b>
                            $ {allDetailResponse[0].reserve_price}
                          </b>
                        )}
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput(
                                'reserve_price',
                                true
                              )
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.store_price && (
                  <>
                    <p>
                      <span>Store Price</span>
                    </p>
                    {editInput.store_price.editable ? (
                      <div className="editPrice">
                        <input
                          type="number"
                          min="1"
                          id="store_price"
                          value={
                            editInput.store_price.value
                              ? editInput.store_price.value
                              : ''
                          }
                          onKeyPress={event => {
                            handleNegative(event);
                            this.handleEnterClick(
                              // "store_price",
                              event,
                              allDetailResponse[0].store_price !==
                                editInput.store_price.value
                            );
                          }}
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput(
                              'store_price',
                              false
                            )
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        {allDetailResponse[0].store_price && (
                          <b>$ {allDetailResponse[0].store_price}</b>
                        )}
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput(
                                'store_price',
                                true
                              )
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj &&
                  configurationObj.second_chance_price && (
                    <>
                      <p>
                        <span>Second Chance Offer Price</span>
                      </p>
                      {editInput.second_chance_price.editable ? (
                        <div className="editPrice">
                          <input
                            type="number"
                            min="1"
                            id="second_chance_price"
                            value={
                              editInput.second_chance_price.value
                                ? editInput.second_chance_price.value
                                : ''
                            }
                            onKeyPress={event => {
                              handleNegative(event);
                              this.handleEnterClick(
                                // "second_chance_price",
                                event,
                                allDetailResponse[0]
                                  .second_chance_price !==
                                  editInput.second_chance_price.value
                              );
                            }}
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'second_chance_price',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          {allDetailResponse[0]
                            .second_chance_price && (
                            <b>
                              ${' '}
                              {
                                allDetailResponse[0]
                                  .second_chance_price
                              }
                            </b>
                          )}
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'second_chance_price',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj && configurationObj.cost && (
                  <>
                    <p>
                      <span>Cost</span>
                    </p>
                    <div className="editPrice">
                      {allDetailResponse[0].cost && (
                        <b>$ {allDetailResponse[0].cost}</b>
                      )}
                    </div>
                  </>
                )}
                {configurationObj && configurationObj.margin && (
                  <>
                    <p>
                      <span>Product Margin</span>
                    </p>
                    {editInput.margin.editable ? (
                      <div className="editPrice">
                        <input
                          type="number"
                          min="1"
                          id="margin"
                          value={
                            editInput.margin.value
                              ? editInput.margin.value
                              : ''
                          }
                          onKeyPress={event => {
                            handleNegative(event);
                            this.handleEnterClick(
                              // "margin",
                              event,
                              allDetailResponse[0].margin !==
                                editInput.margin.value
                            );
                          }}
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('margin', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        {allDetailResponse[0].margin && (
                          <b>$ {allDetailResponse[0].margin}</b>
                        )}
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('margin', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.min_price && (
                  <>
                    <p>
                      <span>Minimum Price</span>
                    </p>
                    {editInput.min_price.editable ? (
                      <div className="editPrice">
                        <input
                          type="number"
                          min="1"
                          id="min_price"
                          value={
                            editInput.min_price.value
                              ? editInput.min_price.value
                              : ''
                          }
                          onKeyPress={event => {
                            handleNegative(event);
                            this.handleEnterClick(
                              // "min_price",
                              event,
                              allDetailResponse[0].min_price !==
                                editInput.min_price.value
                            );
                          }}
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('min_price', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        {allDetailResponse[0].min_price && (
                          <b>$ {allDetailResponse[0].min_price}</b>
                        )}
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('min_price', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj && configurationObj.max_price && (
                  <>
                    <p>
                      <span>Maximum Price</span>
                    </p>
                    {editInput.max_price.editable ? (
                      <div className="editPrice">
                        <input
                          type="number"
                          min="1"
                          id="max_price"
                          value={
                            editInput.max_price.value
                              ? editInput.max_price.value
                              : ''
                          }
                          onKeyPress={event => {
                            handleNegative(event);
                            this.handleEnterClick(
                              // "max_price",
                              event,
                              allDetailResponse[0].max_price !==
                                editInput.max_price.value
                            );
                          }}
                          onChange={this.handleEditInputChange}
                        />
                        <CloseIcon
                          alt="Close Edit"
                          onClick={() =>
                            this.handleEditInput('max_price', false)
                          }
                          className="on"
                        />
                      </div>
                    ) : (
                      <div className="editPrice">
                        {allDetailResponse[0].max_price && (
                          <b>$ {allDetailResponse[0].max_price}</b>
                        )}
                        <i>
                          <CreateIcon
                            onClick={() =>
                              this.handleEditInput('max_price', true)
                            }
                            title="Update Price"
                          />
                        </i>
                      </div>
                    )}
                  </>
                )}
                {configurationObj &&
                  configurationObj.estimated_shipping_cost && (
                    <>
                      <p>
                        <span>Estimated Shipping Cost</span>
                      </p>
                      {editInput.estimated_shipping_cost.editable ? (
                        <div className="editPrice">
                          <input
                            type="number"
                            min="1"
                            id="estimated_shipping_cost"
                            value={
                              editInput.estimated_shipping_cost.value
                                ? editInput.estimated_shipping_cost
                                    .value
                                : ''
                            }
                            onKeyPress={event => {
                              handleNegative(event);
                              this.handleEnterClick(
                                // "estimated_shipping_cost",
                                event,
                                allDetailResponse[0]
                                  .estimated_shipping_cost !==
                                  editInput.estimated_shipping_cost
                                    .value
                              );
                            }}
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'estimated_shipping_cost',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          {allDetailResponse[0]
                            .estimated_shipping_cost && (
                            <b>
                              ${' '}
                              {
                                allDetailResponse[0]
                                  .estimated_shipping_cost
                              }
                            </b>
                          )}
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'estimated_shipping_cost',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj &&
                  configurationObj.goat_lowest_price && (
                    <>
                      <p>
                        <span>Goat Lowest Price</span>
                      </p>
                      {editInput.goat_lowest_price.editable ? (
                        <div className="editPrice">
                          <input
                            type="number"
                            min="1"
                            id="goat_lowest_price"
                            value={
                              editInput.goat_lowest_price.value
                                ? editInput.goat_lowest_price.value
                                : ''
                            }
                            onKeyPress={event => {
                              handleNegative(event);
                              this.handleEnterClick(
                                event,
                                allDetailResponse[0]
                                  .goat_lowest_price !==
                                  editInput.goat_lowest_price.value
                              );
                            }}
                            onChange={this.handleEditInputChange}
                          />
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'goat_lowest_price',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          {allDetailResponse[0]
                            .goat_lowest_price && (
                            <b>
                              ${' '}
                              {
                                allDetailResponse[0]
                                  .goat_lowest_price
                              }
                            </b>
                          )}
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'goat_lowest_price',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj &&
                  configurationObj.is_external_quantity_blocked && (
                    <>
                      <h5>Quantity</h5>
                      <p>
                        <span>Block External Quantity</span>
                      </p>
                      {editInput.is_external_quantity_blocked
                        .editable ? (
                        <div className="editPrice">
                          <input
                            type="checkbox"
                            id="is_external_quantity_blocked"
                            checked={
                              editInput.is_external_quantity_blocked
                                .value
                            }
                            onChange={this.handleDropDownEditPublish}
                          />
                          <p>
                            {editInput.is_external_quantity_blocked
                              .value
                              ? 'Yes'
                              : 'No'}
                          </p>
                          <CloseIcon
                            alt="Close Edit"
                            onClick={() =>
                              this.handleEditInput(
                                'is_external_quantity_blocked',
                                false
                              )
                            }
                            className="on"
                          />
                        </div>
                      ) : (
                        <div className="editPrice">
                          <b>
                            {allDetailResponse[0]
                              .is_external_quantity_blocked
                              ? 'Yes'
                              : 'No'}
                          </b>
                          <i>
                            <CreateIcon
                              onClick={() =>
                                this.handleEditInput(
                                  'is_external_quantity_blocked',
                                  true
                                )
                              }
                              title="Update Price"
                            />
                          </i>
                        </div>
                      )}
                    </>
                  )}
                {configurationObj &&
                  configurationObj.distribution_center_quantity && (
                    <>
                      <h5>Distribution Centers</h5>
                      {allDetailResponse[0]
                        .distribution_center_quantity.length > 0 && (
                        <table>
                          <thead>
                            <tr>
                              <th>Distribution Center</th>
                              <th>Code</th>
                              <th>Quantity (0)</th>
                            </tr>
                          </thead>
                          <tbody>{distributionCenters}</tbody>
                        </table>
                      )}
                    </>
                  )}
              </div>
              {['Master', 'Parent'].includes(
                allDetailResponse[0].product_type
              ) && (
                <div id="child_product">
                  <h5>
                    <i className="fa fa-server" aria-hidden="true" />{' '}
                    Child Products
                  </h5>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleChildrenSave}
                  >
                    Save
                  </button>
                  <div>
                    {allDetailResponse.length > 0 &&
                    allDetailResponse[0].children &&
                    allDetailResponse[0].children.length > 0 ? (
                      <table>
                        <tr>
                          <th>SKU</th>
                          <th>Title</th>
                          <th>Product Type</th>
                          <th>Remove</th>
                        </tr>
                        {allDetailResponse[0].children &&
                          allDetailResponse[0].children.map(item => {
                            return (
                              <tr>
                                <td>{item.sku}</td>
                                <td>{item.title}</td>
                                <td>{item.product_type}</td>
                                <td>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      this.handleDeleteChild(
                                        item.sku
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    />
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                      </table>
                    ) : (
                      ''
                    )}
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        this.handleClickedForShowChildTable()
                      }
                    >
                      Open Child
                    </button>

                    {this.state.isChildTableVisible ? (
                      <div style={{ width: '100%', height: '100%' }}>
                        <div
                          id="myGrid"
                          style={{
                            height: '100%',
                            width: '100%'
                          }}
                          className="ag-theme-alpine"
                        >
                          <AgGridReact
                            columnDefs={columnDefs}
                            // rowData={rowData}
                            defaultColDef={defaultColDef}
                            onGridReady={this.onGridReady}
                            // onRowSelected={onRowSelected}
                            onSelectionChanged={
                              this.onSelectionChanged
                            }
                            paginationPageSize={5}
                            pagination={true}
                            cacheBlockSize={5}
                            animateRows={true}
                            rowSelection="multiple"
                            // loadingCellRendererParams={loadingCellRendererParams} // renderer Params to load msg
                            noRowsOverlayComponentParams={
                              this.noRowsOverlayComponentParams
                            } // default no rows overlay component
                            noRowsOverlayComponent={
                              this.state.noRowsOverlayComponent
                            }
                            loadingCellRenderer={
                              this.state.loadingCellRenderer
                            }
                            loadingOverlayComponentParams={
                              this.state
                                .loadingOverlayComponentParams
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
              <div className="content-box">
                <h5>Attributes</h5>
                {allDetailResponse[0].attributes.length > 0 && (
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>{attributesDetail}</tbody>
                  </table>
                )}
              </div>
            </div>
          )}
          <LoadingOverlay showOverlay={loading} />
          {this.state.open ? (
            <CopyProductModal
              open={this.state.open}
              sku={
                allDetailResponse &&
                allDetailResponse[0] &&
                allDetailResponse[0].sku
              }
              title={
                allDetailResponse &&
                allDetailResponse[0] &&
                allDetailResponse[0].title
              }
              quantity={
                allDetailResponse &&
                allDetailResponse[0] &&
                allDetailResponse[0].total_available_quantity
              }
              closeCopyProduct={this.closeCopyProduct}
              copyProductWithoutSibling={
                this.copyProductWithoutSibling
              }
              product_ids={
                allDetailResponse &&
                allDetailResponse[0] &&
                allDetailResponse[0].id
              }
            />
          ) : null}
        </div>
      </>
    );
  }
}
