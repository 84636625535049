import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import SweetAlert from "react-bootstrap-sweetalert";

import CheckOutlinedIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Link } from "react-router-dom";


export default class errorComponent extends Component {
  componentDidMount() {
    this.props.hideOverlay(false);
  }
  

  render() {
    // console.log("dskjk", this.props.response.data.message)
    if (this.props.status === "redirectTrue") {
      return <Redirect to="/error" />;
    }

      const removeBoundaryQuotes = (string) =>
      typeof(this.props.response.data.message) === "string"  && string.replace(/^["'](.+(?=["']$))["']$/, "$1");


    const constructParagraphs = (message) => {
      const messageWithoutQuotes = message && removeBoundaryQuotes(message);
      return (
        <p>
          <i>{messageWithoutQuotes}</i>
        </p>
      );
    };

    const messageArrayMaps = (array) =>
    array && array.map((item) => constructParagraphs(item));

    const messageParagraphs = (item) =>
      item && Array.isArray(item)
        ? messageArrayMaps(item)
        : constructParagraphs(item);

    const messageSection = this.props.response && this.props.response.data && this.props.response.data.message
      ? messageParagraphs(this.props.response.data.message)
      : this.props.response && this.props.response.data && this.props.response.data.errors
      ? messageParagraphs(this.props.response.data.errors)
      : this.props.response.data?.detail || "Errors Found";

    return (
      <div className="noheading">
        <SweetAlert
          title=""
          showConfirm
          showCancel={false}
          onConfirm={() =>
            this.props.status === null
              ? this.props.hideAlert(null, this.props.action)
              : this.props.status
              ? this.props.hideAlert("success", this.props.action)
              : this.props.hideAlert("error", this.props.action)
          }
          style={{ display: "block", marginTop: "-150px" }}
        >
          {this.props.status  ? (
            // publishRespose
            <>
              {
                this.props.response.data && this.props.response.data.is_archived && this.props.response.data.is_archived ?
                  <>
                    <CheckOutlinedIcon className="errorOutlineIcon completed" />
                    <p>"{this.props.orderId ? this.props.orderId : null} Order Archived" </p>
                  </>
                  : this.props.response.data.is_archived === false ?
                    <>
                      <CheckOutlinedIcon className="errorOutlineIcon completed" />
                      <p> "{this.props.orderId ? this.props.orderId : null} Order Unarchived"</p>
                    </> :
                    this.props.response.data.message &&
                      this.props.response.data.message.length > 0 && this.props.response.data.message[0].marketplace && this.props.response.data.message[0].sku ?
                      (
                        <div className="popScroll">
                          <CheckOutlinedIcon className="errorOutlineIcon completed" />
                          <table className="forPublishTable">
                            <tr>
                              <th style={{ textAlign: "center", verticalAlign: "middle" }}> SKU</th>
                              <th style={{ textAlign: "center", verticalAlign: "middle" }}> marketplace</th>
                              <th style={{ textAlign: "center", verticalAlign: "middle" }}> Status</th>
                              <th > Error</th>
                            </tr>
                            {this.props.response.data.message.map(a => {
                              return (
                                <tr >
                                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>{a.sku}</td>
                                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>{a.marketplace}</td>
                                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>{a.status === true ? <DoneIcon fontSize="small" /> : a.status === false ? <CancelIcon fontSize="small" /> : a.status}</td>
                                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>{a.error}</td>
                                </tr>
                              )
                            })}
                          </table>
                        </div>) :
                      this.props.response.data.message && this.props.response.data.message.length > 0 && this.props.response.data.message[0] && this.props.response.data.message[0].value === "Synced with Channel Advisor" ?
                        (
                          <div className="popScroll">
                            <CheckOutlinedIcon className="errorOutlineIcon completed" />
                            <table className="forPublishTable">
                              <tr>
                                <th style={{ textAlign: "center", verticalAlign: "middle" }}> SKU</th>
                                <th style={{ textAlign: "center", verticalAlign: "middle" }}> Value</th>
                              </tr>
                              {this.props.response.data.message.map(a => {
                                return (
                                  <tr >
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>{a.sku}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>{a.value}</td>
                                  </tr>
                                )
                              })}
                            </table>
                          </div>) : this.props.response.data.message && this.props.response.data.message.length > 0 && this.props.response.data.message[0] && this.props.response.data.message[0].order_id ?
                          (
                            <div className="popScroll">
                              <CheckOutlinedIcon className="errorOutlineIcon completed" />
                              <table className="forPublishTable">
                                <tr>
                                  <th style={{ textAlign: "center", verticalAlign: "middle" }}>Order Id</th>
                                  <th style={{ textAlign: "center", verticalAlign: "middle" }}> Status</th>
                                </tr>
                                {this.props.response.data.message.map(a => {
                                  return (
                                    <tr >
                                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>{a.order_id}</td>
                                      <td style={{ textAlign: "center", verticalAlign: "middle" }}>{a.status}</td>
                                    </tr>
                                  )
                                })}
                              </table>
                            </div>) 

                            // editCategoryUi
                            : this.props.response.data.message  && (typeof this.props.response.data.message === "string") && this.props.response.data.message !== 'Product created.'?
                          (
                            <div className="popScroll">
                              <CheckOutlinedIcon className="errorOutlineIcon completed" />
                              <p>{`${JSON.stringify(this.props.response.data.message)}`}</p>
                            </div>)
                            
                            : this.props.response.data.message && this.props.response.data.message.sku && this.props.response.data.message.error ?
                            (
                              <div className="popScroll">
                                <CheckOutlinedIcon className="errorOutlineIcon completed" />
                                <table className="forPublishTable">
                                  <tr>
                                    <th style={{ textAlign: "center", verticalAlign: "middle" }}> SKU</th>
                                    <th style={{ textAlign: "center", verticalAlign: "middle" }}> Status</th>
                                    <th>Message</th>
                                  </tr>
                                  <tr >
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>{this.props.response.data.message.sku}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>{this.props.response.data.message.status === true ? <DoneIcon fontSize="small" /> : this.props.response.data.message.status === false ? <CancelIcon fontSize="small" /> : this.props.response.data.message.status}</td>
                                    <td style={{ textAlign: "center", verticalAlign: "middle" }}>{this.props.response.data.message.error}</td>
                                  </tr>
                                </table>
                              </div>) : this.props.response.data.message === "Product created."  || (this.props.response.data.message && this.props.response.data.sku && this.props.response.data.copy=== true) ?
                              <div>
                                <CheckOutlinedIcon className="errorOutlineIcon completed" />
                                  <h6><b>{this.props.response.data.message}</b></h6>
                                  <Link to="/catalogs" className="btn btn-default goto"> <i  aria-hidden="true" />Go To CatalogPage</Link>
                              </div> :
                               this.props.response.data.message && this.props.response.data.sku && this.props.response.data.product?
                               <div>
                               <CheckOutlinedIcon className="errorOutlineIcon completed" />
                               <h6><b>{this.props.response.data.message}</b></h6>
                               <Link to={`/catalogs/${this.props.response.data.product}`} className="btn btn-default goto"> <i  aria-hidden="true" />Go To CatalogDetailPage</Link>
                               </div>:
                             
                            <>
                            
                              {/* <CheckOutlinedIcon className="errorOutlineIcon completed" /> */}
                              {/* <p>helooo{`${JSON.stringify(this.props.response.data.message)}`}</p> */}

                              {/* SyncResponseUI */}
                              {this.props.response.data.message && this.props.response.data.message[0].marketplace && this.props.response.data.message[0].status && (
                                   <div className="popScroll">
                                   <CheckOutlinedIcon className="errorOutlineIcon completed" />
                                     <table className="forPublishTable">
                                      <tr>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }}> marketplace</th>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }}> Status</th>
                                        <th > Error</th>
                                      </tr>
                                   {this.props.response.data.message && this.props.response.data.message[0].marketplace && this.props.response.data.message[0].status && this.props.response.data.message.map(a => {
                                      return (
                                      <tr >
                                      {/* <td style={{ textAlign: "center", verticalAlign: "middle" }}>{a.sku}</td> */}
                                       <td style={{ textAlign: "center", verticalAlign: "middle" }}>{a.marketplace}</td>
                                       <td style={{ textAlign: "center", verticalAlign: "middle",width:'120px' }}>{a.status === true ? <DoneIcon fontSize="small" />: <CancelIcon fontSize="small" />  }</td>
                                       <td style={{ textAlign: "center", verticalAlign: "middle",fontSize:'1rem' }}>{a.status !== true && a.status  }</td>
                                    </tr>
                                  )
                                })}
                              </table>
                            </div>
                               )} 
                           
                            </>
                         }     
                        </>
                     ) : (
            <>
              <ErrorOutlineIcon className="errorOutlineIcon" />
              { this.props.response && this.props.response.data && this.props.response.data.message && (typeof this.props.response.data.message === "object" ||
              this.props.response && this.props.response.data &&
                typeof this.props.response.data.error === "object") && (
                  <>
                    <h5>Something Went Wrong!</h5>
                    <h6>Please check with the System Administrator</h6>
                    <p>
                      <i>
                        <b>Status Code: {this.props.response.status}</b>
                        <br />
                      {/* PayLoad: {this.props.response.config.data} */}
                      </i>
                    </p>
                  </>
                )}
               {messageSection}
            </>
          )}
        </SweetAlert>
      </div>
    );
  }
}