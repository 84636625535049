export function getDateBeforeNDays(deltaDays = 0) {
    const date = new Date();
    if (deltaDays) {
        date.setDate(date.getDate() - deltaDays);
    }

    // Set hours, minutes, seconds, and milliseconds to zero
    date.setUTCHours(0, 0, 0, 0);

    return date.toISOString();
}

export function getUTCDateTimeRange(startDate, endDate) {
    const startUtcDateTime = new Date(startDate).toISOString();

    let endUtcDateTime = null;

    if (endDate) {
        endUtcDateTime = new Date(endDate);
    } else {
        endUtcDateTime = new Date(startDate);
    }
    endUtcDateTime.setDate(endUtcDateTime.getDate() + 1);
    endUtcDateTime = endUtcDateTime.toISOString();

    return [startUtcDateTime, endUtcDateTime]

}
