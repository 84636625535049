import React, { Component } from 'react';

export default class OrderListStatusBarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totals: {
        total_platform_amount: 0,
        total_platform_shipping_fee: 0,
        total_cost: 0,
        total_roi: 0,
        total_profit_margin: 0
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.state.totals) !==
      JSON.stringify(prevProps.totals)
    ) {
      this.setState({ totals: prevProps.totals });
    }
  }

  render() {
    const {
      total_platform_amount,
      total_payout,
      total_shipping_cost,
      total_cost,
      total_profit,
      total_roi,
      total_profit_margin
    } = this.state.totals;

    return (
      <div
        className="ag-status-name-value"
        style={{
          margin: 0,
          marginTop: 5,
          background: 'white',
          padding: '12px 6px',
          width: '100%',
          textAlign: 'right',
          fontWeight: 400,
        }}
      >
        <span className="component">Total Order: </span>
        <span className="ag-status-name-value-value">
          {total_platform_amount?.toLocaleString()}
        </span>,&nbsp;&nbsp;
        <span className="component">Total Payout: </span>
        <span className="ag-status-name-value-value">
          {total_payout?.toLocaleString()}
        </span>,&nbsp;&nbsp;
        <span className="component">
          Total Shipping Cost:{' '}
        </span>
        <span className="ag-status-name-value-value">
          {total_shipping_cost?.toLocaleString()}
        </span>,&nbsp;&nbsp;
        <span className="component">Total Cost: </span>
        <span className="ag-status-name-value-value">
          {total_cost?.toLocaleString()}
        </span>,&nbsp;&nbsp;
        <span className="component">Total Profit: </span>
        <span className="ag-status-name-value-value">
          {total_profit?.toLocaleString()}
        </span>,&nbsp;&nbsp;
        <span className="component">Total Profit Margin: </span>
        <span className="ag-status-name-value-value">
          {total_profit_margin?.toLocaleString()} %{' '}
        </span>,&nbsp;&nbsp;
        <span className="component">Total ROI: </span>
        <span className="ag-status-name-value-value">
          {total_roi?.toLocaleString()} %
        </span>
      </div>
    );
  }
}
