import React from 'react';
import { Backdrop, Fade, makeStyles, Modal } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  content: {
    maxWidth: 500,
    maxHeight: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

export function BaseModal(props) {
  const { open, onClose, children } = props;
  const classes = useStyles();

  return (
    <Modal
      open={open}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      onClose={onClose}
      BackdropComponent={Backdrop}
    >
      <Fade in={open}>
        <div className={classes.container}>
          <div className={classes.content}>
            {children}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
