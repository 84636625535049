import React, { Component, useMemo } from "react";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import MessageComponent from "../FunctionalComponents/messageComponent";
import {
  BUSINESS_RULE_API,
} from "../../configurations/configApi";
import Permission from "../../permission/Permission";
import loadingRender from "../../formatters/loadingRender";
import BusinessRuleRender from "../../formatters/BusinessRuleRender";
import MenuList from "../../gridComponents/MenuList";
import CustomNoRowsOverlay from "../../formatters/customNoRowsOverlay";
import BusinessRuleDelete from "views/FunctionalComponents/BusinessRuleDelete";
import { apiDelete, apiEdit } from "../../apiActions/action";
import BusinessRuleModal from "../../Modal/Modal";
import { handleError } from "../FunctionalComponents/ErrorHandle";
import errorRedirect from "views/FunctionalComponents/ErrorRedirect";

var pId = "";
var load = false;
var responseOnGrid = "";

class BusinessRule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [
        {
          headerName: "",
          field: "",
          // filter: "agNumberColumnFilter",
          lockVisible: true,
          cellRendererFramework: (params) =>
            <div>
              {params.data.fields_used === 0 ? <i className="fa fa-trash" onClick={() => this.handleBusinessRuleDelete(params)}></i> : null}
            </div>
        },
        {
          headerName: "Name",
          field: "name",
          sortable: true,
          filter: "agTextColumnFilter",
          cellRendererFramework: (params) =>
            <div>
              <div
                type="button"
                style={{ color: "#0ba6d4" }}
                onClick={() => this.handleCreateBusinessRuleDetails(params.data)}
              >{params.data.name}</div>
            </div>,
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
            ]
          }
        },

        {
          headerName: "Description",
          field: "description",
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true, // it will remove AND/OR conditions
            filterOptions: [
              "contains",
              "notContains",
              "equals",
              "notEqual",
              "startsWith",
              "endsWith",
              "In List",
              "Not In List"
            ]
          }
        },

        {
          headerName: "Used by",
          field: "fields_used",
          sortable: true,
          filter: "agNumberColumnFilter",
          filterParams: {
            buttons: ["reset"],
            debounceMs: 1000,
            suppressAndOrCondition: true
          }
        },

        {
          headerName: "Updated On",
          field: "updated_on",
          // filter:"agDateColumnFilter"
        },

        {
          headerName: "Created On",
          field: "created_on",
          // filter: "agDateColumnFilter"
        },

      ],
      defaultColDef: {
        flex: 1,
        resizable: true,
        tooltipComponent: "customTooltip",
        floatingFilter: true
      },
      rowModelType: "serverSide",
      frameworkComponents: {
        customNoRowsOverlay: CustomNoRowsOverlay,
        loadingRender: loadingRender,
        BusinessRuleRender: BusinessRuleRender
      },
      loadingCellRenderer: "loadingRender",
      loadingCellRendererParams: { loadingMessage: "One moment please..." },
      loadingOverlayComponent: "loadingRender",
      totalProductsCount: 0,
      catalogfilterCount: 0,
      noRowsOverlayComponent: "customNoRowsOverlay",
      noRowsOverlayComponentParams: {
        noRowsMessageFunc: function () {
          return "Sorry - No Products!";
        }
      },
      tooltipShowDelay: 0,
      filterStatus: false,
      product_ids: [],
      showAction: false,
      saveBtn: false,
      countNill: true,
      paginationPageSize: 100,
      cacheBlockSize: 100,
      context: { componentParent: this },
      saveInput: false, // to show the input tag, when save as is given
      displayDropDownConfig: [], // to get multiple-labels response
      activeId: "", // to show active Label in dropdown
      saveCheck: false,
      isCreateBusinessRules: false,
      BusinessRuleDataPerId: "",
      open: false,
      stringValue:""

    };
    this.fetchMessage = this.fetchMessage.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.onFilterCount = this.onFilterCount.bind(this);
    this.onShowAction = this.onShowAction.bind(this);
    this.handleBusinessRuleDelete = this.handleBusinessRuleDelete.bind(this);
  }

  componentDidMount() {
    pId = "";
    localStorage.setItem("page", false); // to set pagination
    load = false;
    responseOnGrid = "";

  }

  // this function will trigger whenever column width is changed
  onColumnMoved = event => {
    this.setState({ saveBtn: load ? true : false });
  };

  // this function will trigger whenever column visiblity is changed
  onColumnVisible = event => {
    responseOnGrid && load && this.setState({ saveBtn: load ? true : false });
  };

  // function to set the total and filtered count
  onFilterCount = (status, filterCount, totalCount) => {
    status
      ? this.setState({ filterStatus: true, countNill: true })
      : this.setState({ filterStatus: false, countNill: true });
    this.setState({ totalCount, filterCount });
  };

  // function to hide count
  onCountNill = () => {
    this.setState({ countNill: false });
  };

  clearFilters = () => {
    this.gridApi.setFilterModel(null);
  };

  paginationSetUp = setUpPage => {
    localStorage.setItem("page", setUpPage);
    window.location = "/business_rule";
  };

  // function to show Action dropdown
  onShowAction = showActionBool => {
    this.setState({ showAction: showActionBool });
  };


  // function to remove sweetalert popups
  hideAlert = mode => {
    this.setState({ sweetAlert: "" });
    // load grid when shipment got success
    mode === "success" && this.onGridReady(this.state.params);
    // deselect all rows when api got success or error
    (mode === "success" || mode === "error") &&
      this.state.params.api.deselectAll();

    this.setState({ isCreateBusinessRules: false })
  };

  // function to show no rows overlay
  onBtShowNoRows = () => {
    this.gridApi.showNoRowsOverlay();
  };

  // check the error status
  checkFunction = error => {
    if (
      error.response &&
      error.response.status &&
      (error.response.status === 404 || error.response.status === 500)
      //  || !error.response
    ) {
      return true;
    }
  };

  // function to show Message pops on success/error response
  fetchMessage = (status, response, stateOfAction) => {
    const message = (
      <MessageComponent
        action={stateOfAction}
        page="buainess_rule"
        hideAlert={this.hideAlert}
        hideOverlay={this.onBtShowHideLoading}
        response={response}
        status={status}
      />
    );
    this.setState({ sweetAlert: message });
  };



  // initialize grid components
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setState({ params: params });

    var datasource = createServerSideDatasource(
      this.onBtShowNoRows,
      this.onFilterCount,
      this.onShowAction,
      this.onCountNill
    );
    params.api.setServerSideDatasource(datasource);
  };


  // Api for Create and update function
  editBusinessRuleListPage = (functionValue, name, description, marketplace, sku) => {
    // this.setState({ sweetAlert : " "})
    let urlForSave;
    let methodOfSave;
    let dataOfSave;
    this.onBtShowHideLoading(true);
    dataOfSave = {
      // marketplace: marketplace,
      formula: functionValue,
      uploaded_by: localStorage.getItem("userId"),
      name: name,
      description: description,
      product_preview_sku: sku,
      marketplace: marketplace

    }
    let BusinessRuleId = this.state.BusinessRuleDataPerId && this.state.BusinessRuleDataPerId.id;
    if (this.state.BusinessRuleDataPerId) {
      urlForSave = `${BUSINESS_RULE_API}${BusinessRuleId}/`
      methodOfSave = "put";
    } else {
      urlForSave = BUSINESS_RULE_API
      methodOfSave = "post";
    }
    if(functionValue && name && description) {
    apiEdit(urlForSave, dataOfSave, methodOfSave, ({ status, response }) => {
      if (status) {
        this.fetchMessage(true, response, "Business rule");
        // this.onGridReady()
        this.onBtShowHideLoading(false);
        this.setState({ BusinessRuleDataPerId: "", open: false });
      } else {
        this.setState({  open: true })
        if (this.checkFunction(response)) {
          this.fetchMessage("redirectTrue");
        } else {
          if (response.response &&
            response.response.status &&
            ((response.response.status === 401))

          ) {
            handleError(response.response);
          }
          else if (this.checkFunction(response)) {
            this.fetchMessage("redirectTrue");
          } else {
            this.fetchMessage(false, response.response, "Business rule");
          }
        }
      }
      });
    };
  }

  // close create and update  modal function
  handleCloseModal = () => {
    this.setState({
      BusinessRuleDataPerId: "",
      open: false,
      stringValue:""
    })
  }

  // button handle click for create business rule
  handleCreateBusinessRule = () => {
    this.setState({
      isCreateBusinessRules: false,
      BusinessRuleDataPerId: "",
      open: true,
      stringValue:"Create"
    })
  }

  // update business rule function
  handleCreateBusinessRuleDetails = (data) => {
    let value = data && data
    this.setState({
      isCreateBusinessRules: true,
      BusinessRuleDataPerId: value,
      open: true,
      stringValue:"Edit"
    })
  }

  // function to show/hide loading overlay components
  onBtShowHideLoading = showHideState => {
    //  if true: show loading component
    // else hide loading component
    showHideState
      ? this.gridApi.showLoadingOverlay()
      : this.gridApi.hideOverlay();
  };

  // single delete for business rule
  handleBusinessRuleDelete = (value) => {
    const sweet = (
      <BusinessRuleDelete
        handleBusinessDelete={this.handleBusinessDelete}
        product_ids={value.data.id}
        hideAlert={this.hideAlert}
      />
    );
    this.setState({ sweetAlert: sweet });
  }
  // handle function delete business rule
  handleBusinessDelete = (data) => {
    this.setState({ sweetAlert: "" });
    let id = data.product_ids
    this.onBtShowHideLoading(true);
    apiDelete(`${BUSINESS_RULE_API}${id}/`,
      "delete",
      ({ status, response }) => {
        if (status) {
          this.fetchMessage(true, response, "delete");
        } else {
          if (response.response &&
            response.response.status &&
            ((response.response.status === 401))

          ) {
            handleError(response.response);
          }
          else if (this.checkFunction(response)) {
            this.fetchMessage("redirectTrue");
          } else {
            this.fetchMessage(false, response.response, "delete");
          }
        }
      });
  }

  render() {
    // console.log("426", orderArchiveData)
    const {
      autoGroupColumnDef,
      columnDefs,
      context,
      defaultColDef,
      detailCellRendererParams,
      frameworkComponents,
      getRowNodeId,
      loadingCellRenderer,
      loadingOverlayComponent,
      noRowsOverlayComponent,
      noRowsOverlayComponentParams,
      rowModelType,
      rowSelection,
      sweetAlert,
      tooltipShowDelay,
      BusinessRuleDataPerId,
      open,
      stringValue
    } = this.state;

    return (
      <>
        <Permission />
        <MenuList />
        <div className="heading brule">
          <h1 id="order-title">
            Rule{" "}
          </h1>
          <div className="create_business_rule">
            <button
              type="button"
              onClick={() => this.handleCreateBusinessRule()}
            >
              <i class="fa fa-plus"></i> Create Rule
            </button>
          </div>
          <div className="profileConfig">
            <div className="dropdown auction">
              <button>
                <i className="fas fa-undo" /> Reset
              </button>
              <div id="myDropdown" className="dropdown-content">
                <p onClick={() => this.clearFilters()}>
                  <i className="fas fa-trash-alt" aria-hidden="true" />{" "}
                  Clear All Filters
                </p>
                <p onClick={() => this.paginationSetUp(false)}>
                  <i className="fas fa-level-down-alt" aria-hidden="true" />{" "}
                  Enable Infinite Scrolling
                </p>
                <p onClick={() => this.paginationSetUp(true)}>
                  <i
                    className="fas fa-angle-double-right"
                    aria-hidden="true"
                  />{" "}
                  Enable Pagination
                </p>
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
        {sweetAlert}
        {open ?
        <BusinessRuleModal
          open={open}
          handleClose={this.handleCloseModal}
          templateBusinessRule={BusinessRuleDataPerId}
          createUpdateBusinessRuleListPage={this.editBusinessRuleListPage}
          stringValue={stringValue}
      /> : null}
        <div
          id="myGrid"
          style={{
            height: "calc(100% - 160px)",
            width: "100%"
          }}
          className="ag-theme-alpine orders"
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={this.onGridReady}
            rowModelType={rowModelType}
            masterDetail={true}
            cacheBlockSize={100}
            maxBlocksInCache={10}
            detailCellRendererParams={detailCellRendererParams}
            frameworkComponents={frameworkComponents}
            loadingCellRenderer={loadingCellRenderer}
            loadingOverlayComponent={loadingOverlayComponent}
            getRowNodeId={getRowNodeId}
            autoGroupColumnDef={autoGroupColumnDef}
            setAlwaysShowHorizontalScroll={true}
            loadingCellRendererParams={this.loadingCellRendererParams} // renderer Params to load msg
            // rowModelType="infinite"
            context={context} // to pass the fns via this obj to other components
            rowSelection={rowSelection} // enable type of selection
            rowMultiSelectWithClick={true} // enable multiple row selection with clicks
            tooltipShowDelay={tooltipShowDelay} // to show tooltip action with delay of time
            noRowsOverlayComponent={noRowsOverlayComponent}
            noRowsOverlayComponentParams={noRowsOverlayComponentParams}
            onColumnResized={this.onColumnMoved.bind(this)}
            pagination={localStorage.getItem("page")} // if true, it enables pagination otherwise infinite scrolling
          />
        </div>
      </>
    );
  }
}

// Function to return sort params
const getSort = params => {
  return params.request.sortModel[0].colId &&
    params.request.sortModel[0].sort === "asc"
    ? params.request.sortModel[0].colId
    : `-${params.request.sortModel[0].colId}`;
};

// Function to return filter condition key
const getCondition = (name, condition) => {
  if (condition === "greaterThan") {
    return `${name}__gt`;
  } else if (condition === "greaterThanOrEqual") {
    return `${name}__gte`;
  } else if (condition === "lessThan") {
    return `${name}__lt`;
  } else if (condition === "lessThanOrEqual") {
    return `${name}__lte`;
  } else if (condition === "contains") {
    return `${name}__icontains`;
  } else if (condition === "notContains") {
    return `exclude|${name}__icontains`;
  } else if (condition === "startsWith") {
    return `${name}__istartswith`;
  } else if (condition === "endsWith") {
    return `${name}__iendswith`;
  } else if (condition === "equals") {
    return `${name}`;
  } else if (condition === "notEqual") {
    return `exclude|${name}`;
  } else {
    return `${name}`;
  }
};


// Function to return filter value params
const getFilter = params => {
  var filterData;
  var rangeData = {};
  var data;
  var rangeDataList = {};

  var mapObj = Object.keys(params.request.filterModel).map(each => {
    // console.log("704",params.request.filterModel)
    data = getCondition(each, params.request.filterModel[each].type);
    filterData = {};
    if (params.request.filterModel[each].type === "inRange") {
      let newRange = [];
      newRange.push(
        params.request.filterModel[each].filter,
        params.request.filterModel[each].filterTo
      );
      rangeDataList.push({ [data]: newRange });
      rangeDataList[data] = newRange;
      rangeData["range"] && delete rangeData["range"];
      rangeData["range"] = rangeDataList;
    } else {
      filterData[data] = params.request.filterModel[each].filter;
    }

    return filterData;
  });
  Object.keys(rangeData).length > 0 && mapObj.push(rangeData);
  return mapObj;
};

function createServerSideDatasource(
  onBtShowNoRows,
  onFilterCount,
  onShowAction,
  onCountNill
) {
  return {
    getRows: function (params) {
      let sortId = "";
      let page = params.request.endRow / 100;
      let filter = [];
      let search = [];

      pId && JSON.stringify(search.push(pId));
      if (
        params.request.sortModel.length > 0 ||
        Object.keys(params.request.filterModel).length > 0 ||
        params.request.groupKeys.length > 0
      ) {
        filter = getFilter(params).filter(e => Object.keys(e).length > 0);
        if (params.request.sortModel.length) {
          sortId = `&ordering=${getSort(params)}`;
        } else if (params.request.groupKeys.length) {
          //TODO
          // sortId = "brand";
          // filter = [{ asin__icontains: "B0040UMIJW" }]
        }
      } else {
        sortId = "";
      }
      var soniServe = `${BUSINESS_RULE_API}?page=${page}&search=${JSON.stringify(
        search
      )}&order_by=[${sortId}]&filter=${filter ? JSON.stringify(filter) : "[]"}`;

      // console.log("2350", urlParams)
      const headers = {
        Authorization: `Token ${localStorage.getItem("token")}`
      };
      onCountNill();
      axios(soniServe, {
        method: "get",
        headers
      })
        .then(response => {
          Object.keys(params.request.filterModel).length === 0
            ? onFilterCount(false, 0, response.data.results)
            : onFilterCount(
              true,
              response.data.count,
              response.data.results
            );
          responseOnGrid = response.data.results;
          if (response.data.results.length > 0) {
            onShowAction(true); // to enable Action Dropdown
            let lastRow = (results, request) => {
              if (!results) return undefined;
              var currentLastRow = request.startRow + results.length;
              return currentLastRow < request.endRow
                ? currentLastRow
                : undefined;
            };
            params.successCallback(
              response.data.results,
              lastRow(response.data.results, params.request)
            );
          } else {
            onBtShowNoRows();
            onShowAction(false); // to disable Action Dropdown
            params.successCallback([{ columnNameField: "No Data" }], 0);
          }
        })
        .catch(error => {
          responseOnGrid = "";
          setTimeout(() => {
            if (error.response &&
              error.response.status &&
              ((error.response.status === 401))
            ) {
              handleError(error.response);
            }
            else errorRedirect(error.response)
          }, 1000)
        });
    }
  };

}

export default BusinessRule;