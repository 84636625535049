import { Link } from 'react-router-dom';
import { apiDelete, apiEdit } from 'apiActions/action';
import Button from 'components/CustomButtons/Button';
import {
  ORDER_ITEM_API_URL,
  ORDERS_API_URL
} from 'configurations/configApi';
import { BaseModal } from 'Modal/BaseModal';
import React, { useCallback, useEffect, useState } from 'react';
import LoadingOverlay from 'components/overlays/LoadingOverlay';

// Function to split the date and time
const splitTime = (time, index) => {
  var splitted = time.split('|');
  return splitted[index];
};

// component which returns Invoice Details
export const Invoice = props => {
  const { allResponse, fetchOrderDetail } = props;
  const [confirmModal, setConfirmModal] = useState({
    itemId: '',
    productTitle: '',
    open: false
  });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const order_id = allResponse ? allResponse[0]?.id : '';
  const order_items = allResponse ? allResponse[0].items : [];
  const showEditFields =
    !!order_items.length &&
    !!order_items.find(item => item.canceled);

  useEffect(() => {
    setData(allResponse ? allResponse[0] : {});
  }, [allResponse]);

  const handleDeleteOrderItem = () => {
    setLoading(true);
    apiDelete(
      `${ORDER_ITEM_API_URL}${confirmModal.itemId}`,
      'delete',
      async ({ status }) => {
        if (status) {
          await fetchOrderDetail();
        }
        setLoading(false);
        handleCloseModal();
      }
    );
  };

  const handleConfirmDeleteItem = useCallback(
    (itemId, productTitle) => {
      setConfirmModal({
        itemId,
        productTitle,
        open: true
      });
    },
    []
  );

  const handleCloseModal = () => {
    setConfirmModal({
      itemId: '',
      productTitle: '',
      open: false
    });
  };

  const handleInputChange = e => {
    const newValue = e.target.value;
    const name = e.target.name;
    if (/^\d*\.?\d*$/.test(newValue)) {
      setData(v => ({ ...v, [name]: newValue }));
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    apiEdit(
      `${ORDERS_API_URL}${order_id}/`,
      {
        shipping_cost: data.shipping_cost,
        restock_fee: data.restock_fee
      },
      'patch',
      async ({ status, response }) => {
        if (status) {
          setData(v => ({ ...v, ...response.data }));
        }
        setLoading(false);
      }
    );
  };

  const productDetails = order_items.map(each => {
    return (
      <tr>
        <td>
          <div className="orderImage">
            {each.image && <img src={each.image} alt="" />}{' '}
            <i className="fas fa-image" />{' '}
          </div>
        </td>
        <td>{each.title}</td>
        <td>
          <Link to={`/catalogs/${each.product_id}`}>{each.sku}</Link>
        </td>
        <td>{each.qty}</td>
        <td>
          {Number(each.published_product_margin)
            ? `${each.published_product_margin} %`
            : each.published_product_margin}{' '}
        </td>
        <td>{each.cost}</td>
        <td>{each.payout}</td>
        <td>
          {each.canceled ? 'Canceled' : allResponse[0].order_status}
        </td>
        <td>
          {!each.canceled && (
            <i
              class="fa fa-trash"
              aria-hidden="true"
              onClick={() =>
                handleConfirmDeleteItem(each.id, each.title)
              }
            />
          )}
        </td>
      </tr>
    );
  });

  return (
    <div className="voJson">
      <table className="OrderItems">
        <thead>
          <tr>
            <th />
            <th>Title</th>
            <th>SKU</th>
            <th>Qty</th>
            <th>Margin</th>
            <th>Cost</th>
            <th>Payout</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {productDetails}
          <tr className="grandTotal">
            <td colSpan={7}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                Restocking Fee:&nbsp;&nbsp;
                {showEditFields ? (
                  <input
                    required
                    type="text"
                    name="restock_fee"
                    className="form-control"
                    id="restock_fee_input"
                    placeholder="Restock Fee"
                    value={data.restock_fee}
                    style={{
                      padding: '0 10px',
                      width: 100,
                      fontSize: 11,
                      fontWeight: 'bold'
                    }}
                    onChange={handleInputChange}
                  />
                ) : (
                  Number(data.restock_fee)
                )}
                &nbsp;%
              </div>
            </td>
            <td style={{ textAlign: 'right' }}>Amount:</td>
            <td>
              {Number(data.amount)
                ? data.amount
                : data.platform_amount}{' '}
            </td>
          </tr>
          <tr className="grandTotal">
            <td colSpan={7}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                Shipping Cost:&nbsp;&nbsp;
                {showEditFields ? (
                  <input
                    required
                    type="text"
                    name="shipping_cost"
                    className="form-control"
                    id="shipping_cost_input"
                    placeholder="Shipping Cost"
                    value={data.shipping_cost}
                    style={{
                      padding: '0 10px',
                      marginRight: 14,
                      width: 100,
                      fontSize: 11,
                      fontWeight: 'bold'
                    }}
                    onChange={handleInputChange}
                  />
                ) : (
                  Number(data.shipping_cost)
                )}
              </div>
            </td>
            <td>Order Total: </td>
            <td>{Number(data.platform_amount)}</td>
          </tr>
          <tr className="grandTotal">
            <td colSpan={7}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                Shipping Fee:&nbsp;&nbsp;
                {Number(data.amount)
                  ? data.shipping_fee
                  : data.platform_shipping_fee}
              </div>
            </td>
            <td style={{ textAlign: 'right' }}>Payout: </td>
            <td>{Number(data.payout)}</td>
          </tr>
          <tr className="grandTotal">
            <td colSpan={7}>
              {showEditFields && (
                <Button
                  variant="contained"
                  color="danger"
                  size="sm"
                  onClick={handleUpdate}
                  style={{
                    float: 'right',
                    padding: '5px 20px',
                    margin: 0
                  }}
                >
                  Update
                </Button>
              )}
            </td>
            <td>Grand Total: </td>
            <td>
              {Number(data.amount)
                ? Number(data.amount) +
                  Number(data.shipping_fee)
                : Number(data.platform_amount) +
                  Number(data.platform_shipping_fee)}
            </td>
          </tr>
        </tbody>
      </table>
      <LoadingOverlay showOverlay={loading} />
      <BaseModal open={confirmModal.open} onClose={handleCloseModal}>
        <>
          <h4 id="transition-modal-title">
            <span style={{ fontWeight: 400 }}>
              Are you sure to delete this order item
            </span>{' '}
            - {confirmModal.productTitle}?
          </h4>
          <div>
            <Button size="sm" onClick={handleCloseModal} link>
              Cancel
            </Button>
            <Button
              color="rose"
              size="sm"
              onClick={handleDeleteOrderItem}
            >
              Yes, delete it
            </Button>
          </div>
        </>
      </BaseModal>
    </div>
  );
};

// component which returns Shipping To Address Details
export const ShippingInfo = props => {
  const { allResponse } = props;
  // console.log(allResponse,'alll')
  return (
    <>
      {allResponse[0].ship_to_info ? (
        <>
          <h4>Shipping</h4>
          <p>
            <span>Name:</span>{' '}
            <b>
              {allResponse[0].ship_to_info.firstName +
                '  ' +
                allResponse[0].ship_to_info.lastName}{' '}
            </b>
          </p>
          <p>
            <span>Company Name:</span>{' '}
            <b>
              {allResponse[0].ship_to_info.companyName ||
                allResponse[0].ship_to_info.company_name}
            </b>
          </p>
          <p>
            <span> City:</span>{' '}
            <b>
              {allResponse[0].ship_to_info.city
                ? allResponse[0].ship_to_info.city
                : ''}
            </b>
          </p>
          <p>
            <span> Country:</span>{' '}
            <b>
              {allResponse[0].ship_to_info.country
                ? allResponse[0].ship_to_info.country
                : ''}
            </b>
          </p>
          <p>
            <span>Address:</span>{' '}
            <b>
              {allResponse[0].ship_to_info.addressLine1 ||
                allResponse[0].ship_to_info.address_line1}{' '}
              {allResponse[0].ship_to_info.addressLine2 ||
                allResponse[0].ship_to_info.address_line2}{' '}
              {/* {allResponse[0].ship_to_info.postalCode ? allResponse[0].ship_to_info.postalCode : ""} */}
            </b>
          </p>
          <p>
            <span>Email:</span>{' '}
            <b>
              {allResponse[0].ship_to_info.emailAddress ||
                allResponse[0].ship_to_info.email_address}
            </b>
          </p>
          <p>
            <span>Day Time Phone:</span>{' '}
            <b>
              {allResponse[0].ship_to_info.daytimePhone ||
                allResponse[0].ship_to_info.daytime_phone}
            </b>
          </p>
          <p>
            <span>Evening Time Phone:</span>{' '}
            <b>
              {allResponse[0].ship_to_info.eveningPhone ||
                allResponse[0].ship_to_info.evening_phone}
            </b>
          </p>
        </>
      ) : (
        'No Shipping and Billing Info Available'
      )}
    </>
  );
};

// component which returns Shipstationstore list =
export const ShipstationStoreList = props => {
  const { shipstationStore } = props;
  // console.log(shipstationStore,'shipstationStore')
  return (
    <>
      {shipstationStore[0] ? (
        shipstationStore[0].map(itm => (
          <div className="shipstationsRow">
            <p>
              <span>Name:</span> {itm.storeName}
            </p>
            <p>
              <span>Marketplace Name:</span> {itm.marketplaceName}
            </p>
            <p>
              <span>Company Name:</span> {itm.companyName}
            </p>
            <p>
              <span>Store Id:</span> {itm.storeId}
            </p>
            <p>
              <span>Active:</span> {itm.active ? 'True' : 'False'}
            </p>
            <p>
              <span>CreatedAt:</span> {itm.createDate}
            </p>
            <p>
              <span>ModifiedAt:</span> {itm.modifyDate}
            </p>
            <p>
              <span>RefreshDate:</span> {itm.refreshDate}
            </p>
          </div>
        ))
      ) : (
        <h4>No stores</h4>
      )}
    </>
  );
};

// component which returns Shipstationstore list =
export const ShipstationOrderList = props => {
  const { shipstationOrders } = props;
  // console.log(shipstationOrders,'shiporders')
  return (
    <>
      <div className="shipstation-orders">
        <table>
          <tr>
            <th>orderId</th>

            <th>customerEmail</th>
            <th>amountPaid</th>
            <th>phone</th>
            <th>​orderStatus</th>

            <th>order Date</th>
            <th>modify Date</th>
          </tr>
          {shipstationOrders ? (
            shipstationOrders.map(itm => (
              <tr>
                <td>{itm.id}</td>

                <td>{itm.customer_email}</td>
                <td>{itm.amount_paid}</td>
                <td>{itm.bill_to.phone}</td>
                <td>{itm.order_status}</td>

                <td>{itm.order_date}</td>
                <td>{itm.modify_date}</td>
              </tr>
            ))
          ) : (
            <h1>No orders</h1>
          )}
        </table>
      </div>
    </>
  );
};

// component which returns Billing Details
export const BillingInfo = props => {
  const { allResponse } = props;
  return (
    <>
      {allResponse[0].ship_to_bill_info ? (
        <>
          <h4>Billing</h4>
          <p>
            <span>Name:</span>{' '}
            <b>
              {allResponse[0].ship_to_bill_info.firstName +
                '  ' +
                allResponse[0].ship_to_bill_info.lastName}
            </b>
          </p>
          <p>
            <span>Company Name:</span>{' '}
            <b>
              {allResponse[0].ship_to_bill_info.companyName ||
                allResponse[0].ship_to_bill_info.company_name}
            </b>
          </p>
          <p>
            <span> City:</span>{' '}
            <b>
              {allResponse[0].ship_to_bill_info.city
                ? allResponse[0].ship_to_bill_info.city
                : ''}
            </b>
          </p>
          <p>
            <span> Country:</span>{' '}
            <b>
              {allResponse[0].ship_to_bill_info.country
                ? allResponse[0].ship_to_bill_info.country
                : ''}
            </b>
          </p>
          <p>
            <span>Address:</span>{' '}
            <b>
              {allResponse[0].ship_to_bill_info.addressLine1 ||
                allResponse[0].ship_to_bill_info.address_line1}{' '}
              {allResponse[0].ship_to_bill_info.addressLine2 ||
                allResponse[0].ship_to_bill_info.address_line2}
            </b>
          </p>
          <p>
            <span>Email:</span>{' '}
            <b>
              {allResponse[0].ship_to_bill_info.emailAddress ||
                allResponse[0].ship_to_bill_info.email_address}
            </b>
          </p>
          <p>
            <span>Day Time Phone:</span>{' '}
            <b>
              {allResponse[0].ship_to_bill_info.daytimePhone ||
                allResponse[0].ship_to_bill_info.daytime_phone}
            </b>
          </p>
          <p>
            <span>Evening Time Phone:</span>{' '}
            <b>
              {allResponse[0].ship_to_bill_info.eveningPhone ||
                allResponse[0].ship_to_bill_info.evening_phone}
            </b>
          </p>
        </>
      ) : (
        ''
      )}
    </>
  );
};

// component which returns Shipment Details
export const ShipmentDetails = props => {
  const { allResponse } = props;

  return (
    <>
      {allResponse[0].shipping_items.length > 0 && (
        <>
          <div className="shipping">
            <h4>Shipment Details</h4>
            <div>
              <p>
                <span>Carrier Code:</span>{' '}
                <b>
                  {allResponse[0].shipping_items[0].carrier_code}
                </b>
              </p>
              <p>
                <span>Tracking Number:</span>{' '}
                <b>{allResponse[0].shipping_items[0].tracking_no}</b>
              </p>
              <p>
                <span>Master Tracking Number:</span>{' '}
                <b>
                  {
                    allResponse[0].shipping_items[0]
                      .master_tracking_no
                  }
                </b>
              </p>
              <p>
                <span>Reference Number:</span>{' '}
                <b>
                  {allResponse[0].shipping_items[0].reference_no}
                </b>
              </p>
              <p>
                <span>Shipped On:</span>{' '}
                <b>
                  {splitTime(
                    allResponse[0].shipping_items[0].ship_time,
                    0
                  )}{' '}
                  {splitTime(
                    allResponse[0].shipping_items[0].ship_time,
                    1
                  )}
                </b>
              </p>
            </div>
          </div>
          <div className="billing">
            <h4>Shipped From:</h4>{' '}
            <p>
              <span>City:</span>{' '}
              <b>
                {
                  allResponse[0].shipping_items[0].ship_from_info
                    .city
                }
              </b>
            </p>
            <p>
              <span>Country:</span>{' '}
              <b>
                {
                  allResponse[0].shipping_items[0].ship_from_info
                    .country
                }
              </b>
            </p>
            <p>
              <span>Address:</span>{' '}
              <b>
                {allResponse[0].shipping_items[0].ship_from_info
                  .addressLine1 ||
                  allResponse[0].shipping_items[0].ship_from_info
                    .address_line1}{' '}
                {allResponse[0].shipping_items[0].ship_from_info
                  .addressLine2 ||
                  allResponse[0].shipping_items[0].ship_from_info
                    .address_line2}
              </b>
            </p>
            <p>
              <span>Email:</span>{' '}
              <b>
                {allResponse[0].shipping_items[0].ship_from_info
                  .emailAddress ||
                  allResponse[0].shipping_items[0].ship_from_info
                    .email_address}
              </b>
            </p>
          </div>
        </>
      )}
    </>
  );
};

// component which returns Order History Tracking
export const OrderHistory = props => {
  const { allResponse } = props;

  const historyDetail =
    allResponse.length > 0 &&
    allResponse[0].order_history.length > 0 &&
    allResponse[0].order_history.map((eachHistory, index) => (
      <>
        <li
          className={`added ${
            allResponse[0].order_history.length === index + 1 &&
            (eachHistory.order_status
              .toLowerCase()
              .startsWith('cancel') ||
              eachHistory.order_status
                .toLowerCase()
                .startsWith('complete'))
              ? ''
              : `last`
          }`}
        >
          <div>
            <p title="Actual Time">
              {splitTime(eachHistory.order_received_time, 0)}
            </p>
            <span>
              {splitTime(eachHistory.order_received_time, 1)}
            </span>
          </div>
          <div>
            <p>
              {eachHistory.order_status.toUpperCase()[0] +
                eachHistory.order_status.substr(
                  1,
                  eachHistory.order_status.length
                )}
            </p>
            {index === 0 && (
              <p>
                Received :{' '}
                <span>
                  {splitTime(eachHistory.order_placed_time, 0)}{' '}
                  {splitTime(eachHistory.order_placed_time, 1)}
                </span>
              </p>
            )}
          </div>
        </li>
      </>
    ));

  return (
    <div className="voJsons">
      {allResponse[0].order_history.length > 0 ? (
        <ul className="timeline">{historyDetail}</ul>
      ) : (
        <p className="noData">
          <span>
            <i className="fas fa-coins" />
            No History to Show
          </span>
        </p>
      )}
    </div>
  );
};

// component which returns Shipping To Address Details
export const WeyBillsInfo = props => {
  const { allResponse } = props;
  return (
    <>
      {allResponse[0].waybill && allResponse[0].waybill[0] ? (
        <>
          <h4>WayBills</h4>
          <p>
            <span>Tracking Number:</span>{' '}
            <b>{allResponse[0].waybill[0].trackingNo}</b>
          </p>
          <p>
            <span>Ship label value:</span>{' '}
            <a
              style={{ color: '#02b2e6' }}
              href={
                allResponse && allResponse[0]
                  ? allResponse[0].waybill[0].shipLabelValue
                  : ''
              }
              target="_blank"
            >
              Ship Label Value
            </a>
          </p>
        </>
      ) : (
        ''
      )}
    </>
  );
};
