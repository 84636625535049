import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import tasksStyle from "assets/jss/material-dashboard-pro-react/components/tasksStyle.jsx";

import RecentOrders from "./RecentOrders";
import PriceHistory from "./PriceHistory";
import StockHistory from "./StockHistory";
import PublishUnpublishHistory from "./PublishUnpublishHistory";
import VOJson from "./VOJson";
import { Invoice } from "views/FunctionalComponents/orderDetailComponent";

class Tasks extends React.Component {
  render() {
    return (
      <>
        {this.props.recentOrders ? (
          <RecentOrders orders={this.props.orders} />
        ) : this.props.action === "stockHistoryTab" ? (
          <StockHistory stockHistory={this.props.stockHistory} />
        ) : this.props.action === "priceHistoryTab" ? (
          <PriceHistory priceHistory={this.props.priceHistory} />
        ) : this.props.action === "invoiceOrders" ? (
          <Invoice allResponse={this.props.allResponse} fetchOrderDetail={this.props.fetchOrderDetail} />
        ) : this.props.action === "voJsonOrderTab" ? (
          <VOJson orderPage orderId={this.props.allResponse} />
        ) : this.props.action === "pubUnpubHistoryTab" ? (
          <PublishUnpublishHistory id={this.props.id} />
        ) : (
          <VOJson catalogPage id={this.props.id} isPublishToVo={this.props.isPublishToVo} isPublishToShopify={this.props.isPublishToShopify} />
        )}
      </>
    );
  }
}

Tasks.propTypes = {
  orders: PropTypes.array,
  productHistory: PropTypes.array,
  mpn: PropTypes.string
};

export default withStyles(tasksStyle)(Tasks);
